import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';

import { openDocument } from '../../../actions';

export const DownloadDocumentButton = props => {
  const onClick = () => {
    if (props.data.objType) {
      return props.openDocument(props.data);
    }
    const mappedDoc = {
      deliverableType: props.data.deliverableType,
      fileName: props.data.fileName,
      objectType: props.data.objVer
        ? props.data.objVer.Type
        : props.data.objectType,
      objectDocumentId: props.data.objVer
        ? props.data.objVer.ID
        : props.data.objectDocumentId,
      objectVersion: props.data.objVer
        ? props.data.objVer.Version
        : props.data.objectVersion,
      filedocumentId: props.data.fileId
        ? props.data.fileId
        : props.data.filedocumentId,
    };
    return props.openDocument(mappedDoc);
  };
  return (
    <NavLink className="download-button" href="#" onClick={onClick}>
      <i className="fa fa-file-download"></i>
    </NavLink>
  );
};

DownloadDocumentButton.propTypes = {
  openDocument: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default connect(null, { openDocument })(DownloadDocumentButton);
