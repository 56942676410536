import { NotificationManager } from 'react-notifications';

import * as types from './Types';
import { Events } from '../services/Constants';
import { loadPlans } from './PlanActions';
import { addEvent } from './EventActions';
import CrmService from '../services/CrmService';
import { withLoading } from './CommonActions';
import { loadPlanRelationshipsResources, loadProfileResources, loadSkyanAuthorization } from './Resources';

export const loadPlanRelationships = (skipCache = false) =>
  loadPlanRelationshipsResources(skipCache, o => {
    if (o !== '') {
      return o[0].planRelationships;
    }
    return { not_found: true };
  });

export const loadProfile = (skipCache = false) =>
  loadProfileResources(skipCache);

export const saveProfile = withLoading(profile => {
  return (dispatch, getState) => {
    const {
      okta: {
        accessToken,
        isEmployee,
        user: { email },
      },
    } = getState();
    return CrmService()
      .saveProfile(accessToken, profile)
      .then(() => {
        return dispatch(loadProfile(true)).then(() => {
          dispatch(addEvent(Events.UPDATE_PROFILE));
          dispatch({ type: types.SAVE_PROFILE_SUCCESS });
          return dispatch(loadPlans(email, isEmployee, true)).then(() => {
            NotificationManager.success(
              'Profile saved successfully.',
              'Profile'
            );
          });
        });
      })
      .catch(() => {
        NotificationManager.error('Unable to save profile.', 'Profile');
      });
  };
});

export const userHaveSkyanAccess = () => {
  return (dispatch, getState) => {
    const {
      okta: {
        user: { email },
      },
    } = getState();
    return dispatch(loadSkyanAuthorization(email));
  };
};
