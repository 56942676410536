import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  selectPlan,
  loadLetterPlans,
  isPlanAdmin,
  canViewAdminTab,
} from '../../actions';
import { slug } from '../../services/SlugService';
import Constants from '../../services/Constants';
import FPLRouter from './FPLRouter';
import MyManagersRouter from './MyManagersRouter';
import NotFound from '../NotFound';

class PlansRouter extends Component {
  withMain = (selectedView, viewParams) => {
    return this.props.withMain(selectedView, viewParams);
  };

  onPlans = (props, viewParams) => {
    return this.withMain(Constants.PLANS_VIEW, viewParams);
  };

  onTeam = (props, viewParams) => {
    return this.onDocuments(Constants.PLAN_TEAM_VIEW, viewParams);
  };

  onAdmin = (props, viewParams) => {
    return this.onDocuments(Constants.ADMIN_VIEW, viewParams);
  };

  onReview = (props, viewParams) => {
    return this.onDocuments(Constants.REVIEW_VIEW, viewParams);
  };

  onDocuments = (documentView, viewParams) => {
    return this.withMain(Constants.DOCUMENTS_VIEW, {
      ...viewParams,
      documentView,
    });
  };

  onClientDocuments = (props, viewParams) => {
    return this.onDocuments(Constants.PLAN_DOCUMENTS_VIEW, viewParams);
  };

  onPrivateMkt = (props, viewParams) => {
    return this.onDocuments(Constants.PLAN_PRIVATE_MARKET_VIEW, viewParams);
  };

  onNEPCDocuments = (props, viewParams) => {
    return this.onDocuments(Constants.PLAN_RESEARCH_DOCUMENTS_VIEW, viewParams);
  };

  onFpl = ({ match }, viewParams) => {
    return (
      <FPLRouter
        match={match}
        selectedPlan={this.props.selectedPlan}
        onDocuments={this.onDocuments}
        viewParams={viewParams}
      />
    );
  };

  onMarketDocs = (props, viewParams) => {
    return this.onDocuments(Constants.PLAN_MARKET_UPDATES_VIEW, viewParams);
  };

  onMyManagers = ({ match }, viewParams) => {
    return (
      <MyManagersRouter
        match={match}
        selectedPlan={this.props.selectedPlan}
        onDocuments={this.onDocuments}
        viewParams={viewParams}
      />
    );
  };

  loadSelectPlan = _.debounce(plan => {
    this.props.selectPlan(plan);
  }, 200);

  tryIsPlanLoaded = (clientLetter, slugPlanName) => {
    const {
      selectedPlan,
      plans,
      isEmployee,
      plansByLetter,
      loadLetterPlans,
    } = this.props;
    // Wait until the plans are loaded
    if (plans === undefined) {
      return false;
    }
    // Load plan from URL slug
    if (slugPlanName) {
      const findPlan = plansList =>
        _.find(plansList, plan => slug(plan.planName) === slugPlanName);
      // Find Slug Plan on user plans
      let plan = findPlan(plans);
      if (!plan) {
        // Find Slug Plan on all employee plans (by letter)
        if (isEmployee) {
          const firstLetter =
            clientLetter === '-' ? '#' : clientLetter.toUpperCase();
          loadLetterPlans(firstLetter);
          plan = findPlan(plansByLetter);
          if (!plan) {
            return false;
          }
        }
      }
      if (plan && selectedPlan !== plan) {
        this.loadSelectPlan(plan);
        return false;
      }
    }
    return true;
  };

  onNotFound = () => {
    return <NotFound />;
  };

  render() {
    const { match, selectedPlan, user } = this.props;
    const { clientLetter, slugPlanName } = match.params;
    if (!this.tryIsPlanLoaded(clientLetter, slugPlanName)) {
      return this.onPlans();
    }
    if (!selectedPlan) {
      return <Redirect push="true" to="/portfolios" />;
    }
    const viewParams = {
      plan: selectedPlan,
      planUrl: match.url,
    };
    return (
      <Switch>
        <Route
          path={`${match.url}/team`}
          render={props => this.onTeam(props, viewParams)}
        />
        {Boolean(
          user &&
            (isPlanAdmin(selectedPlan, user) ||
              canViewAdminTab(this.props.isEmployee, selectPlan))
        ) && (
          <Route
            path={`${match.url}/admin`}
            render={props => this.onAdmin(props, viewParams)}
          />
        )}
        <Route
          path={`${match.url}/review`}
          render={props => this.onReview(props, viewParams)}
        />
        <Route
          path={`${match.url}/client`}
          render={props => this.onClientDocuments(props, viewParams)}
        />
        {Boolean(selectedPlan.accessToPrivateMarketDocs) && (
          <Route
            path={`${match.url}/private-market`}
            render={props => this.onPrivateMkt(props, viewParams)}
          />
        )}
        <Route
          path={`${match.url}/nepc`}
          render={props => this.onNEPCDocuments(props, viewParams)}
        />
        <Route
          path={`${match.url}/fpl`}
          render={props => this.onFpl(props, viewParams)}
        />
        {Boolean(selectedPlan.accessToMarketUpdates) && (
          <Route
            path={`${match.url}/market`}
            render={props => this.onMarketDocs(props, viewParams)}
          />
        )}
        <Route
          path={`${match.url}/manager`}
          render={props => this.onMyManagers(props, viewParams)}
        />
        <Route render={props => this.onMyManagers(props, viewParams)} />
      </Switch>
    );
  }
}

PlansRouter.propTypes = {
  match: PropTypes.object.isRequired,
  withMain: PropTypes.func.isRequired,
  selectPlan: PropTypes.func.isRequired,
  plansByLetter: PropTypes.array.isRequired,
  loadLetterPlans: PropTypes.func.isRequired,
  selectedPlan: PropTypes.object,
  user: PropTypes.object,
  plans: PropTypes.array,

  isEmployee: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    selectedPlan: state.ui.selectedPlan,
    plans: state.resources.plans.data,
    plansByLetter: state.resources.all_plans.data || [],
    isEmployee: state.okta.isEmployee,
    user: state.okta.user,
  };
};

export default connect(mapStateToProps, {
  selectPlan,
  loadLetterPlans,
})(PlansRouter);
