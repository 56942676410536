import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import Constants from '../../../services/Constants';
import {
  showWhatIsDueDiligenceRating,
  showWhatIsDueDiligenceStatus,
  showWhatAreBetaGroups,
  showWhatIsOwnedLed,
  showWhatIsESGRating,
  showWhatIsDEIRating,
  redirect,
} from '../../../actions';
import DocumentsMenu from './DocumentsMenu';
import DocumentsList from './DocumentsList';
import StrategyContents from './StrategyContents';
import ManagerContents from './ManagerContents';
import MeetingNotesModal from './MeetingNotesModal';
import PlanDDEventModal from './PlanDDEventModal';
import WhatIsModal from './WhatIsModal';
import FPLListHeader from './FPLListHeader';
import FPLStrategyHeader from './FPLStrategyHeader';
import viewParams from '../../router/ViewParams';
import ManagerSummaryHeader from './ManagerSummaryHeader';
import Landing from './Landing';
import LandingMenu from './LandingMenu';
import MarketUpdates from './MarketUpdates';
import PlanDetailsTeam from '../details/PlanDetailsTeam';
import Admin from '../admin/Admin';
import Review from '../admin/Review';

export class Documents extends Component {
  renderHeader() {
    const {
      viewParams: { documentView, fplList, fplStrategy, managerSummary },
    } = this.props;
    if (fplStrategy) {
      return (
        <FPLStrategyHeader
          viewParams={this.props.viewParams}
          strategy={fplStrategy}
          showWhatIsDueDiligenceRating={this.props.showWhatIsDueDiligenceRating}
          showWhatIsDueDiligenceStatus={this.props.showWhatIsDueDiligenceStatus}
          showWhatIsOwnedLed={this.props.showWhatIsOwnedLed}
          showWhatIsESGRating={this.props.showWhatIsESGRating}
          showWhatIsDEIRating={this.props.showWhatIsDEIRating}
          showWhatAreBetaGroups={this.props.showWhatAreBetaGroups}
        />
      );
    }
    if (documentView === Constants.PLAN_FOCUSED_PLACEMENT_LIST) {
      return <FPLListHeader fplList={fplList} />;
    }
    if (
      managerSummary &&
      managerSummary.firmName &&
      (documentView === Constants.EMPLOYEE_MANAGER_ACTIVITIES ||
        documentView === Constants.PLAN_MANAGER_STRATEGIES ||
        documentView === Constants.PLAN_MANAGER_EVEST_NARRATIVES)
    ) {
      return (
        <ManagerSummaryHeader
          showWhatIsOwnedLed={this.props.showWhatIsOwnedLed}
          managerSummary={managerSummary}
        />
      );
    }
    return null;
  }

  renderStrategy() {
    const { documentView, fplStrategy, fplStrategyUrl } = this.props.viewParams;
    if (fplStrategy) {
      return (
        <StrategyContents
          viewParams={this.props.viewParams}
          documentView={documentView}
          strategy={fplStrategy}
          strategyUrl={fplStrategyUrl}
        />
      );
    }
    return null;
  }

  renderManagerPage() {
    const {
      documentView,
      manager,
      managerUrl,
      managerSummary,
    } = this.props.viewParams;
    if (manager) {
      return (
        <ManagerContents
          viewParams={this.props.viewParams}
          documentView={documentView}
          managerSummary={managerSummary}
          managerUrl={managerUrl}
        />
      );
    }
    return null;
  }

  renderDocumentsList() {
    const {
      documentView,
      fplList,
      fplStrategy,
      manager,
      meetingNoteWriteUpId,
      ddEventId,
    } = this.props.viewParams;
    const documentsVisible =
      this.isFplDocumentsVisible(documentView, fplStrategy) ||
      this.isManagerDocumentsVisible(documentView, manager) ||
      this.isNotFplAndManager(fplStrategy, manager);

    return (
      (<DocumentsList
        documentView={documentView}
        className={documentView.replace(/\s/g, '-')}
        visible={documentsVisible}
        fplList={fplList}
        viewParams={this.props.viewParams}
        meetingNoteWriteUpId={meetingNoteWriteUpId}
        ddEventId={ddEventId}
      />)
    );
  }

  isFplDocumentsVisible(documentView, fplStrategy) {
    return (
      fplStrategy &&
      (documentView === Constants.FPL_RESEARCH ||
        documentView === Constants.FPL_MANAGER_ACTIVITIES)
    );
  }

  isManagerDocumentsVisible(documentView, manager) {
    return (
      manager &&
      (documentView === Constants.EMPLOYEE_MANAGER_ACTIVITIES ||
        documentView === Constants.PLAN_MANAGER_STRATEGIES)
    );
  }

  isNotFplAndManager(fplStrategy, manager) {
    return !fplStrategy && !manager;
  }

  renderContent() {
    const { viewParams } = this.props;
    const { documentView } = viewParams;
    const views = {
      [Constants.REVIEW_VIEW]: Review,
      [Constants.PLAN_MARKET_UPDATES_VIEW]: MarketUpdates,
      [Constants.PLAN_TEAM_VIEW]: PlanDetailsTeam,
      [Constants.ADMIN_VIEW]: Admin,
    };
    const ViewComponent = views[documentView];
    if (ViewComponent) {
      return <ViewComponent viewParams={viewParams} />;
    }
    return (
      <div>
        {this.renderHeader()}
        {this.renderStrategy()}
        {this.renderManagerPage()}
        {this.renderLanding()}
        {this.renderDocumentsList()}
      </div>
    );
  }

  renderLanding() {
    const { viewParams, hasPortfolioDDs } = this.props;
    const { documentView } = viewParams;
    if (
      documentView === Constants.PLAN_PORTFOLIO ||
      documentView === Constants.PLAN_MANAGER_NOTES_VIEW
    ) {
      return (
        <div>
          <Landing print={true} />
          <Landing print={false} />
          <LandingMenu
            viewParams={this.props.viewParams}
            documentView={documentView}
            hasPortfolioDDs={hasPortfolioDDs}
          />
        </div>
      );
    }
    return null;
  }

  renderMenu() {
    const {
      hasFpl,
      hasPerformancePortal,
      hasMyManagers,
      planName,
      clientName,
      isPlanAdmin,
      canViewAdminTab,
      viewParams: { documentView },
    } = this.props;
    const accessToMarketUpdates = this.props.viewParams.plan
      .accessToMarketUpdates;
    const accessToPrivateMarketDocs = this.props.viewParams.plan
      .accessToPrivateMarketDocs;
    return (
      <DocumentsMenu
        planName={planName}
        clientName={clientName}
        selection={documentView}
        hasFpl={hasFpl}
        hasMarketUpdates={accessToMarketUpdates}
        hasPerformancePortal={hasPerformancePortal}
        hasMyManagers={hasMyManagers}
        isPlanAdmin={isPlanAdmin}
        canViewAdminTab={canViewAdminTab}
        accessToPrivateMarketDocs={accessToPrivateMarketDocs}
      />
    );
  }

  renderModals() {
    return (
      <div>
        <MeetingNotesModal showPlans={false} consultant={false} />
        <PlanDDEventModal consultant={false} />
        <WhatIsModal />
      </div>
    );
  }

  render() {
    return (
      <Container className="documentsView" fluid>
        <Row>
          <Col sm={12} md={12} lg={2}>
            {this.renderMenu()}
          </Col>
          <Col sm={12} md={12} lg={10}>
            {this.renderContent()}
          </Col>
        </Row>
        {this.renderModals()}
      </Container>
    );
  }
}

Documents.propTypes = {
  viewParams,

  planId: PropTypes.string,
  planName: PropTypes.string,
  clientName: PropTypes.string,
  hasFpl: PropTypes.bool.isRequired,
  hasPerformancePortal: PropTypes.bool.isRequired,
  hasPortfolioDDs: PropTypes.bool.isRequired,
  hasMyManagers: PropTypes.bool.isRequired,
  meetingNoteWriteUpId: PropTypes.string,
  ddEventWriteUpId: PropTypes.string,

  isPlanAdmin: PropTypes.bool.isRequired,
  canViewAdminTab: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  showWhatIsDueDiligenceRating: PropTypes.func.isRequired,
  showWhatIsDueDiligenceStatus: PropTypes.func.isRequired,
  showWhatAreBetaGroups: PropTypes.func.isRequired,
  showWhatIsOwnedLed: PropTypes.func.isRequired,
  showWhatIsESGRating: PropTypes.func.isRequired,
  showWhatIsDEIRating: PropTypes.func.isRequired,
};

const checkIsPlanAdmin = (state, plan) => {
  const user = state.okta.user;
  if (plan && user) {
    const planAdmin = plan.planAdministratorEmail || '';
    const userEmail = user.email || '';
    if (
      userEmail !== '' &&
      planAdmin.toLowerCase() === userEmail.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

const canViewAdminTab = (state, plan) => {
  if (plan) {
    return state.okta.isEmployee && !plan.planAdministratorEmail;
  }
  return false;
};

const mapStateToProps = (state, ownProps) => {
  const {
    viewParams: { plan },
  } = ownProps;
  const selectedPlan = state.ui.selectedPlan;
  const hasFpl = (selectedPlan && selectedPlan.planFplIndicator) || false;
  const hasPerformancePortal =
    (selectedPlan && selectedPlan.performancePortalIndicator) || false;
  const hasPortfolioDDs =
    (selectedPlan && selectedPlan.portfolioDDEventsIndicator) || false;
  const hasMyManagers = (selectedPlan && selectedPlan.accessMyManager) || false;
  const planId = selectedPlan && selectedPlan.planId;
  const planName = selectedPlan && selectedPlan.planName;
  const clientName = selectedPlan && selectedPlan.clientName;
  return {
    hasFpl,
    hasPerformancePortal,
    hasPortfolioDDs,
    hasMyManagers,
    planId,
    planName,
    clientName,
    isPlanAdmin: checkIsPlanAdmin(state, plan),
    canViewAdminTab: canViewAdminTab(state, plan),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showWhatIsDueDiligenceRating,
    showWhatIsDueDiligenceStatus,
    showWhatAreBetaGroups,
    showWhatIsOwnedLed,
    showWhatIsESGRating,
    showWhatIsDEIRating,
    redirect,
  })(Documents)
);
