import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import Constants from '../../../services/Constants';

export const DocumentsFilter = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState(props.selected);

  const onSelect = filter => () => {
    let previusFilter = [filter];
    if (Array.isArray(props.selected) && props.isMultiSelect) {
      if (filter === 'All') {
        previusFilter = 'All';
      } else if (props.selected.includes(filter)) {
        previusFilter = [
          ...props.selected.filter(item => item !== filter && item !== 'All'),
        ];
      } else {
        previusFilter = [
          ...props.selected.filter(item => item !== 'All'),
          filter,
        ];
      }
    }
    const isUnselectingSingleSelect =
      props.selected.includes(filter) && !props.isMultiSelect;
    const filterObject = {
      [props.filterProperty]:
        isUnselectingSingleSelect || previusFilter.length === 0
          ? ['All']
          : previusFilter,
    };
    props.onSelect(filterObject);
    setSelectedDocType(filterObject[props.filterProperty]);
  };

  const items = props.options;
  const { filterBy, filterProperty, documentView } = props;
  const filterPropertyValue = filterBy[documentView]
    ? filterBy[documentView][filterProperty]
    : null;

  const toggle = () => setDropdownOpen(prevState => !prevState);
  if (props.inputType === 'buttons') {
    const buttons = _.map(items, docType => {
      const allItems = !filterPropertyValue && docType === 'All';
      const isSelected =
        filterPropertyValue === docType ||
        filterPropertyValue?.includes(docType);

      const color = isSelected ? '' : 'dark';
      return (
        <Button
          key={docType}
          className={
            isSelected || allItems
              ? 'shadow-none selected filterButton'
              : 'shadow-none filterButton'
          }
          outline={!isSelected}
          color={color}
          onClick={onSelect(docType)}
          disabled={props.isLoading || !props.hasLoaded}>
          {docType}
        </Button>
      );
    });
    return <div>{buttons}</div>;
  }

  const isBetaGroupNotAvailable =
    filterProperty === 'betaGroup' && items?.length === 0;

  const isBetaGroupNotSelected =
    filterProperty === 'betaGroup' && !filterBy[documentView]?.betaGroup;

  const shouldResetAssetClass =
    filterProperty === 'assetClass' &&
    filterBy[Constants.PLAN_FOCUSED_PLACEMENT_LISTS]?.assetClass &&
    filterBy[Constants.PLAN_FOCUSED_PLACEMENT_LISTS]?.assetClass[0] === 'All';

  const renderDropDownValue = () => {
    if (shouldResetAssetClass) return 'All';

    if (isBetaGroupNotAvailable) return 'Loading';

    if (isBetaGroupNotSelected) return '-- select group --';

    if (filterBy[documentView] && filterBy[documentView][filterProperty]) {
      if (filterBy[documentView][filterProperty].length > 1)
        return `${filterBy[documentView][filterProperty][0]} + ${filterBy[
          documentView
        ][filterProperty].length - 1}`;
      return filterBy[documentView][filterProperty];
    }
    return selectedDocType[0] !== 'All' || selectedDocType !== 'All'
      ? selectedDocType
      : 'None';
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      direction="down"
      disabled={props.isLoading || !props.hasLoaded}>
      <DropdownToggle
        color="primary"
        disabled={props.isLoading || !props.hasLoaded}
        caret>
        {renderDropDownValue()}
      </DropdownToggle>
      <DropdownMenu>
        {items.map(docType => {
          const isSelected =
            filterPropertyValue === docType ||
            filterPropertyValue?.includes(docType);
          const color = isSelected ? '' : 'dark';
          return (
            <DropdownItem
              key={docType}
              className={
                isSelected
                  ? 'filterButton shadow-none selected'
                  : 'shadow-none filterButton'
              }
              outline={(!isSelected).toString()}
              color={color}
              onClick={onSelect(docType)}>
              {docType}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = state => {
  return {
    filterBy: state.ui.filterByDocumentType,
  };
};

DocumentsFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  filterBy: PropTypes.object,
  filterProperty: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  inputType: PropTypes.string,
  documentView: PropTypes.string,
  hasLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(mapStateToProps, {})(DocumentsFilter);
