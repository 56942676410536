import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';

export const SimpleTypeahead = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const [options, setOptions] = useState([]);

  const getOptions = () => {
    if (inputSearch !== '') {
      return options.map(opt => (
        <DropdownItem
          onClick={() => {
            toggle();
            props.onChange(opt);
          }}
          key={opt.id}
          id={opt.id}>
          {opt.label}
        </DropdownItem>
      ));
    }
    return <div className="no-rows-message">No rows</div>;
  };

  const handleChangeInput = event => {
    setInputSearch(event.target.value);
    props.loadOptions(event.target.value).then(data => setOptions(data));
  };

  return (
    <InputGroup className={props.className}>
      <Input
        type="text"
        value={inputSearch}
        id={props.id}
        onChange={event => handleChangeInput(event)}
        placeholder={props.placeHolder}
        onClick={toggle}
      />
      <InputGroupButtonDropdown
        addonType="append"
        isOpen={dropdownOpen}
        toggle={toggle}>
        <DropdownToggle outline caret />
        <DropdownMenu left style={{ width: `100%` }}>
          {getOptions()}
        </DropdownMenu>
      </InputGroupButtonDropdown>
    </InputGroup>
  );
};

SimpleTypeahead.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  placeHolder: PropTypes.string,
  customComponents: PropTypes.object || {},
};

export default SimpleTypeahead;
