import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Media } from 'reactstrap';

const TeamMember = ({ member, picture }) => {
  const { title, email, phone, name } = member;

  return (
    <Row className="teamMember">
      <Col sm={1} md={1} lg={1} href="#">
        <Media object src={picture} alt={name} className="img-thumbnail" />
      </Col>
      <Col body>
        <Media heading>{name}</Media>
        <div>{title}</div>
        <div>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        <div>
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      </Col>
    </Row>
  );
};

TeamMember.propTypes = {
  member: PropTypes.object.isRequired,
  picture: PropTypes.any,
};

export default TeamMember;
