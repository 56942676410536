import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const sanitizeData = (text, { title, type = 'string' }) => {
  if (type === 'string') {
    return (<>
      <h6>{title}</h6>
      <span className="text-justify">
        {text
          .replace('\r', '\n')
          .replace(/(\s*\r?\n\r?\s*){2,}/g, '\n\n')
          .replace(/\t/g, '  ')
          .replace(/^\s*\?\s*/gm, '\n\u2022 ')}
      </span>
    </>);
  } else if (type === 'html') {
    const filteredComponent = (
      <div
        dangerouslySetInnerHTML={{
          __html: text
            .replaceAll('\\"', '"')
            .replaceAll('<p>&nbsp;</p>', '')
            .replaceAll('<body>', '<body><div class="page-container">')
            .replaceAll('</body>', '</div></body>')
            .replaceAll('<p><span', '<div class="doc-modal-title"><p><span')
            .replaceAll('</span></p>', '</span></p></div>')
            .replaceAll('\u0002', '-')
            .replaceAll('<br><br>', '<br>'),
          // .replaceAll('</p>', "</p><div class='content-text'>")
          // .replaceAll('<p>', '</div><p>')
          // .replaceAll(
          //   '</strong></span></p>\n',
          //   '\n<br /></strong></span></p>'
          // )
          // .replaceAll('<br>', '') +
          // .replaceAll('<br />', '') +
        }}
      />
    );
    return filteredComponent;
  }
};

const ContentFields = ({ layout, data, whenEmpty = null }) => {
  const items = _.map(layout, content => {
    const value = (_.get(data, content.field, '') || '').trim();
    if (!value) {
      return null;
    }
    return (
      <div key={content.field} className={`text-section`}>
        {sanitizeData(value, content)}
        <hr />
      </div>
    );
  }).filter(x => !!x);
  if (_.isEmpty(items)) {
    return whenEmpty;
  }
  return <div className="contentFieldsArea">{items}</div>;
};

ContentFields.propTypes = {
  layout: PropTypes.array.isRequired,
  data: PropTypes.object,
  whenEmpty: PropTypes.element,
};

export default ContentFields;
