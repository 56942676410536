import { NotificationManager } from 'react-notifications';

import * as types from './Types';
import { Constants } from '../services/Constants';
import { slug, slugInitialLetter } from '../services/SlugService';
import { Storages, addToLocalStorage } from '../services/LocalStorageService';
import EventService from '../services/EventService';
import { withLoading } from './CommonActions';
import { RequestAction } from '../utils/Request';

export const redirect = to => {
  return dispatch => {
    dispatch({ type: types.REDIRECT, to });
  };
};

export const selectInvestmentFirm = investmentFirm => {
  return dispatch => {
    dispatch({ type: types.INVESTMENT_FIRM, investmentFirm });
  };
};

export const saveSortState = sortModel => {
  return dispatch => {
    // FIXME: Check why it is impacting on sorting and blinking the grids
    // dispatch({ type: types.SORT_CHANGE, sortModel });
  };
};

export const saveDocSize = pdfCanvasSize => {
  return dispatch => {
    dispatch({ type: types.PDF_CANVAS_SIZE, pdfCanvasSize });
  };
};

export const saveEmployeeActiveTab = employeeActiveTab => {
  return dispatch => {
    dispatch({ type: types.EMPLOYEE_ACTIVE_TAB, employeeActiveTab });
  };
};

export const saveHideUnpublishedPlans = hide => {
  addToLocalStorage(Storages.HIDE_UNPUBLISHED_PLANS, hide);
  return dispatch => {
    dispatch({ type: types.HIDE_UNPUBLISHED_PLANS, hide });
  };
};

export const changeEmployeeMeetingNotesSwitch = allManagers => {
  return dispatch => {
    dispatch({
      type: types.EMPLOYEE_MANAGER_MEETING_NOTES_SWITCH,
      allManagers,
    });
  };
};

export const changeEmployeeDDEventsSwitch = allManagers => {
  return dispatch => {
    dispatch({
      type: types.EMPLOYEE_DD_EVENTS_SWITCH,
      allManagers,
    });
  };
};

export const redirectPlan = to => {
  return (dispatch, getState) => {
    const {
      ui: { selectedPlan },
    } = getState();
    const planName = (selectedPlan && selectedPlan.planName) || '';
    const clientName = (selectedPlan && selectedPlan.clientName) || '';
    const slugPlanName = slug(planName);
    const slugLetter = slugInitialLetter(clientName);
    return dispatch(redirect(`/portfolio/${slugLetter}/${slugPlanName}${to}`));
  };
};

const changeWhatIs = whatIsVisible => {
  return dispatch => {
    dispatch({
      type: types.TOGGLE_WHAT_IS,
      whatIsVisible,
    });
  };
};

export const hideWhatIs = () => changeWhatIs(null);

export const showWhatIsDueDiligenceRating = () =>
  changeWhatIs(Constants.WHAT_IS_DUE_DILIGENCE_RATING);

export const showWhatIsDueDiligenceStatus = () =>
  changeWhatIs(Constants.WHAT_IS_DUE_DILIGENCE_STATUS);

export const showWhatAreBetaGroups = () =>
  changeWhatIs(Constants.WHAT_IS_BETA_GROUPS);

export const showWhatIsOwnedLed = () =>
  changeWhatIs(Constants.WHAT_IS_OWNED_LED);

export const showWhatIsESGRating = () =>
  changeWhatIs(Constants.WHAT_IS_ESG_RATING);

export const showWhatIsDEIRating = () =>
  changeWhatIs(Constants.WHAT_IS_DEI_RATING);

export const changeDocumentsViewFilter = (documentView, filter) => {
  return dispatch => {
    dispatch({
      type: types.FILTER_DOCUMENT_BY_TYPE,
      documentView,
      filter,
    });
  };
};

export const changeGridFilter = (filterKey, filteredIDs, hasFilter) => {
  return dispatch => {
    dispatch({
      type: types.FILTER_DOCUMENT_ON_GRID,
      filterKey,
      filteredIDs,
      hasFilter,
    });
  };
};

export const openFeedbackModal = (visible = true, data = undefined) => {
  return dispatch => {
    dispatch({
      type: types.TOGGLE_FEEDBACK_MODAL,
      visible: !!visible,
      data,
    });
  };
};

export const hideFeedbackModal = () => {
  return dispatch => {
    dispatch(openFeedbackModal(false));
    dispatch({ type: types.CLEAR_FEEDBACK_MODAL });
  };
};

export const openConfirmationModal = (visible = true) => {
  return dispatch => {
    dispatch({ type: types.TOGGLE_CONFIRMATION_MODAL, visible: !!visible });
  };
};

export const hideConfirmationModal = () => {
  return dispatch => {
    dispatch(openConfirmationModal(false));
  };
};

export const submitFeedback = withLoading((rate, message, viewParams) => {
  return (dispatch, getState) => {
    const {
      okta: {
        user: { name, email },
        accessToken,
      },
    } = getState();
    const pageUrl = window.location.pathname;
    const pageTitle = document.title;
    const { documentView } = viewParams;
    const feedback = {
      rate,
      message,
      name,
      email,
      pageUrl,
      pageTitle,
      documentView,
      // eslint-disable-next-line
      clienthub_version: Constants.BUILD_ID,
    };
    return EventService()
      .feedback(accessToken, feedback)
      .then(() => {
        dispatch(openFeedbackModal(false));
        dispatch({ type: types.CLEAR_FEEDBACK_MODAL });
        NotificationManager.success(
          'Your feedback was submitted successfully!',
          'Give Us Feedback!',
          4000
        );
      })
      .catch(() => {
        NotificationManager.error(
          'Failed to input your feedback, please try again.',
          'Give Us Feedback!',
          3000
        );
      });
  };
});

export const getFeedbackList = withLoading(() => {
  return dispatch => {
    return dispatch(
      RequestAction(types.UI_FEEDBACK, EventService().getFeedbacks)
    );
  };
});

export const dispatchPrinting = printing => {
  return dispatch => dispatch({ type: types.PRINTING, printing });
};
