import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, TabContent, TabPane, Button } from 'reactstrap';
import _ from 'lodash';

import { managerManagerActivitiesColumnDefs } from '../documents/ColumnDefs';
import { renderTableGrid } from '../documents/TablePrintHtml';
import ContentFields from './ContentFields';
import { Constants, Events } from '../../../services/Constants';
import {
  printNepcOpnion,
  printEVestmentNarratives,
  printPortfolio,
} from '../../../actions';
import { loadFPLeVest } from '../../../actions/Resources';
import SimpleTabsMenu from '../../tabsmenu/SimpleTabsMenu';
import LoadingComponentOverlay from '../../LoadingSpinner';

const nepcNarrativesDifferentiatorsLayout = [
  { title: 'Analyst Opinion', field: 'analystOpinion' },
  { title: 'Positives', field: 'positives' },
  { title: 'Negatives', field: 'negatives' },
  { title: 'Investment Thesis', field: 'investmentThesis' },
  { title: 'Firm Organization', field: 'firmOrganization' },
  { title: 'Portfolio Management Team', field: 'portfolioManagementTeam' },
  { title: 'Philosophy', field: 'philosophy' },
  { title: 'Process', field: 'process' },
  { title: 'Portfolio', field: 'portfolio' },
  { title: 'Performance', field: 'performance' },
  { title: 'Product Pricing', field: 'productPricing' },
  { title: 'Tax Considerations', field: 'taxConsiderations' },
];

// const nepcNarrativesProfileLayout = [
//   { title: 'People', field: 'people' },
//   { title: 'Philosophy', field: 'philosophy' },
//   { title: 'Strategy (+ Strategy Continued)', field: 'strategy' },
//   { title: 'Portfolio', field: 'portfolio' },
//   { title: 'Performance', field: 'performance' },
// ];

const nepcEsgFirmFieldsLayout = [
  { title: 'Analyst Opinion', field: 'flEsgAnalystOpinion' },
  { title: 'Firm-Level Commitment', field: 'flEsgFirmLevelCommitment' },
  { title: 'Resources', field: 'flEsgResources' },
  { title: 'EngagementPolicies', field: 'flEsgEngagementPolicies' },
];

const nepcEsgStrategyFieldsLayout = [
  { title: 'Analyst Opinion', field: 'slEsgAnalystOpinion' },
  { title: 'Overview', field: 'slEsgOverview' },
  { title: 'Integration Process', field: 'slEsgIntegrationProcess' },
  { title: 'Resources', field: 'slEsgResources' },
];

const nepcDeiFirmFieldsLayout = [
  { title: 'Analyst Opinion', field: 'flDeiAnalystOpinion' },
  { title: 'Firm-Level Commitment', field: 'flDeiFirmLevelCommitment' },
  { title: 'Diversity', field: 'flDeiDiversity' },
  { title: 'Accountability', field: 'flDeiAccountability' },
  { title: 'Resources', field: 'flDeiResources' },
  { title: 'Engagement', field: 'flDeiEngagement' },
];

const nepcDeiStrategyFieldsLayout = [
  { title: 'Analyst Opinion', field: 'slDeiAnalystOpinion' },
  { title: 'Overview', field: 'slDeiOverview' },
  { title: 'Diversity', field: 'slDeiDiversity' },
  { title: 'Integration', field: 'slDeiIntegration' },
];

const eVestStrategyLayout = [
  { title: 'Investment Strategy', field: 'slDeiIntegration' },
  { title: 'Screening Process', field: 'screeningProcess' },
  {
    title: 'Portfolio Construction Methodology',
    field: 'portfolioConstructionMethodology',
  },
  { title: 'Buy/Sell Discipline', field: 'buySellDiscipline' },
  { title: 'Trading/Execution Strategy', field: 'tradingExecutionStrategy' },
  // { title: "Additional Comments", field: "additionalComments" },
];

export class StrategyContents extends Component {
  haveDeiRating = () => {
    const deiratingFields = Object.keys(this.props.nepcStrategy).filter(
      fieldName =>
        (fieldName.startsWith('flDei') || fieldName.startsWith('slDei')) &&
        this.props.nepcStrategy[fieldName]
    );
    return deiratingFields.length > 0;
  };

  haveEsgRating = () => {
    const deiratingFields = Object.keys(this.props.nepcStrategy).filter(
      fieldName =>
        (fieldName.startsWith('flEsg') || fieldName.startsWith('slEsg')) &&
        this.props.nepcStrategy[fieldName]
    );
    return deiratingFields.length > 0;
  };

  renderTabs = () => {
    const options = [];
    if (_.get(this.props, 'nepcStrategy.lastUpdatedOn')) {
      options.push({
        title: Constants.FPL_NEPC_NARRATIVES,
        path: '/nepc',
      });
    }

    options.push(
      { title: Constants.FPL_MANAGER_ACTIVITIES, path: '/activities' },
      { title: Constants.FPL_EVEST_NARRATIVES, path: '/evest' }
    );

    if (this.haveEsgRating()) {
      options.push({
        title: Constants.FPL_ESG,
        path: '/esg-opinion',
      });
    }

    if (this.haveDeiRating()) {
      options.push({
        title: Constants.FPL_DEI,
        path: '/dei-opinion',
      });
    }

    const { strategyUrl, documentView } = this.props;

    return (
      <SimpleTabsMenu
        options={options}
        baseUrl={strategyUrl}
        selected={documentView}
        prefix="FPL "
      />
    );
  };

  renderContents = () => {
    const renders = {
      [Constants.FPL_NEPC_NARRATIVES]: this.renderNepcStrategy,
      [Constants.FPL_MANAGER_ACTIVITIES]: this.renderFPLManagerActivities,
      [Constants.FPL_EVEST_NARRATIVES]: this.renderEvestStrategy,
      [Constants.FPL_ESG]: this.renderEsgRating,
      [Constants.FPL_DEI]: this.renderDeiRating,
    };
    const nop = () => {};
    const contentRenderer = renders[this.props.documentView] || nop;
    return (
      <TabContent activeTab={this.props.documentView}>
        {contentRenderer()}
      </TabContent>
    );
  };

  renderPrintNepcEsgDei = () => {
    return (
      <Button
        color="secondary"
        className="text-nowrap"
        onClick={this.printOpnion}>
        Download PDF
      </Button>
    );
  };

  printOpnion = () => {
    const getHtml = selector => document.querySelector(selector).innerHTML;
    const disclaimer = getHtml('.fpl-disclaimer-container');
    const header = getHtml('.fpl-header');
    const content = getHtml('.fpl-nepc-strategy');
    const strategyName =
      _.get(this.props.strategy, 'investmentStrategyName', '') || '';
    const data = {
      userMail: this.props.user.email,
      strategyName: strategyName,
      documentType: this.props.documentView,
    };
    const views = {
      [Constants.FPL_NEPC_NARRATIVES]: 'nepc_opinion',
      [Constants.FPL_DEI]: 'esg_dei_opinion',
      [Constants.FPL_ESG]: 'esg_dei_opinion',
    };
    this.props.printNepcOpnion(
      data,
      header.replace(disclaimer, ``),
      views[this.props.documentView],
      this.props.documentView,
      content
    );
  };

  renderPrintNepcStrategy = () => {
    if (!_.get(this.props, 'nepcStrategy.lastUpdatedOn')) {
      return null;
    }
    return (
      <Button
        color="secondary"
        className="text-nowrap"
        onClick={this.printOpnion}>
        Download PDF
      </Button>
    );
  };

  printEVestmentNarratives = () => {
    const getHtml = selector => document.querySelector(selector).innerHTML;
    const disclaimer = getHtml('.fpl-disclaimer-container');
    const header = getHtml('.fpl-header');
    const content = getHtml('.fpl-nepc-eVestment');
    const strategyNameContent = getHtml('.fpl-header h4');
    // get strategy name as the eVest narrative, does not have a property as name
    const strategyName =
      _.get(this.props.strategy, 'investmentStrategyName', '') || '';

    const sendData = {
      userMail: this.props.user.email,
      strategyName: strategyName,
      documentType: 'eVestment Narratives',
    };

    this.props.printEVestmentNarratives(
      sendData,
      header.replace(disclaimer, '').replace(strategyNameContent, ''),
      content
    );
  };

  renderPrintEVestmentNarratives = () => {
    const strategyId = _.get(this.props, 'viewParams.fplStrategy.strategyId');
    this.props.loadFPLeVest(strategyId);
    if (_.isEmpty(this.props.evestStrategy.data)) {
      return null;
    }
    return (
      <Button
        color="secondary"
        className="text-nowrap narrative-download-btn"
        onClick={this.printEVestmentNarratives}>
        Download PDF
      </Button>
    );
  };

  renderEsgRating = () => {
    const whenEmpty = (
      <TabPane tabId={Constants.FPL_ESG}>
        <span className="font-italic">
          NEPC is under non-disclosure for this strategy. For additional
          details, please contact your consultant.
        </span>
      </TabPane>
    );

    const haveStrategyFields = () => {
      const atLeastOneField = nepcEsgStrategyFieldsLayout.some(
        ({ field }) => this.props.nepcStrategy[field]
      );
      return atLeastOneField;
    };
    const haveFirmFields = () => {
      const atLeastOneField = nepcEsgFirmFieldsLayout.some(
        ({ field }) => this.props.nepcStrategy[field]
      );
      return atLeastOneField;
    };
    return (
      <TabPane tabId={Constants.FPL_ESG}>
        <Row className="fpl-nepc-strategy">
          <Col md={12} className="d-flex strategyDownloadDetail">
            <span className="detailTitle">
              {/* Last Updated On <span className="detailUpdatedOn">{lastUpdatedOn}</span> */}
            </span>
            {this.renderPrintNepcEsgDei()}
          </Col>
          <Col lg={2} md={6} sm={12} />
          <Col md={12} lg={12} sm={12} className="mt-2">
            <hr />
            {Boolean(haveFirmFields()) && (
              <>
                <h3>Firm Level</h3>
                <ContentFields
                  layout={nepcEsgFirmFieldsLayout}
                  data={this.props.nepcStrategy}
                  whenEmpty={whenEmpty}
                />
              </>
            )}
            {Boolean(haveStrategyFields()) && (
              <>
                <h3>Strategy Level</h3>
                <ContentFields
                  layout={nepcEsgStrategyFieldsLayout}
                  data={this.props.nepcStrategy}
                  whenEmpty={whenEmpty}
                />
              </>
            )}
          </Col>
        </Row>
      </TabPane>
    );
  };

  renderDeiRating = () => {
    const whenEmpty = (
      <TabPane tabId={Constants.FPL_DEI}>
        <span className="font-italic">
          NEPC is under non-disclosure for this strategy. For additional
          details, please contact your consultant.
        </span>
      </TabPane>
    );

    const haveStrategyFields = () => {
      const atLeastOneField = nepcDeiStrategyFieldsLayout.some(
        ({ field }) => this.props.nepcStrategy[field]
      );
      return atLeastOneField;
    };
    const haveFirmFields = () => {
      const atLeastOneField = nepcDeiFirmFieldsLayout.some(
        ({ field }) => this.props.nepcStrategy[field]
      );
      return atLeastOneField;
    };
    return (
      <TabPane tabId={Constants.FPL_DEI}>
        <Row className="fpl-nepc-strategy">
          <Col md={12} className="d-flex strategyDownloadDetail">
            <span className="detailTitle">
              {/* Last Updated On <span className="detailUpdatedOn">{lastUpdatedOn}</span> */}
            </span>
            {this.renderPrintNepcEsgDei()}
          </Col>
          <Col lg={2} md={6} sm={12} />
          <Col md={12} lg={12} sm={12} className="mt-2">
            <hr />
            {Boolean(haveFirmFields()) && (
              <>
                <h3>Firm Level</h3>
                <ContentFields
                  layout={nepcDeiFirmFieldsLayout}
                  data={this.props.nepcStrategy}
                  whenEmpty={whenEmpty}
                />
              </>
            )}
            {Boolean(haveStrategyFields()) && (
              <>
                <h3>Strategy Level</h3>
                <ContentFields
                  layout={nepcDeiStrategyFieldsLayout}
                  data={this.props.nepcStrategy}
                  whenEmpty={whenEmpty}
                />
              </>
            )}
          </Col>
        </Row>
      </TabPane>
    );
  };

  renderNepcStrategy = () => {
    const whenEmpty = (
      <TabPane tabId={Constants.FPL_NEPC_NARRATIVES}>
        <span className="font-italic">
          NEPC is under non-disclosure for this strategy. For additional
          details, please contact your consultant.
        </span>
      </TabPane>
    );
    // const lastUpdatedOn = formatDate(
    //   _.get(this.props, 'nepcStrategy.lastUpdatedOn', '')
    // );
    return (
      <TabPane tabId={Constants.FPL_NEPC_NARRATIVES}>
        <Row className="fpl-nepc-strategy">
          <Col md={12} className="d-flex strategyDownloadDetail">
            <span className="detailTitle">
              {/* Last Updated On <span className="detailUpdatedOn">{lastUpdatedOn}</span> */}
            </span>
            {this.renderPrintNepcStrategy()}
          </Col>
          <Col lg={2} md={6} sm={12} />
          <Col md={12} lg={12} sm={12} className="mt-2">
            <hr />
            <ContentFields
              layout={nepcNarrativesDifferentiatorsLayout}
              data={this.props.nepcStrategy}
              whenEmpty={whenEmpty}
            />
          </Col>
        </Row>
      </TabPane>
    );
  };

  renderResearchStrategy = () => {
    return <TabPane tabId={Constants.FPL_RESEARCH} />;
  };

  renderFPLManagerActivities = () => {
    const { viewParams, planManagerActivities } = this.props;
    const { documentView } = viewParams;

    const print = () => {
      let grid = '';
      grid = renderTableGrid(
        managerManagerActivitiesColumnDefs,
        viewParams,
        planManagerActivities,
        Constants.ROWS_PER_PAGE_DD_EVENTS,
        { colId: 'date', sort: 'desc' },
        documentView
      );
      const event = Events.PRINT_PORTFOLIO_DDS;

      const planData = {
        planName: viewParams.plan ? viewParams.plan.planName : '',
        documentType: 'Portfolio Summary',
      };

      const html = `
      <h2>Portfolio Summary</h2>
      <div class="grid-view">
        ${grid}
      </div>
    `;
      this.props.printPortfolio(
        event,
        planData,
        viewParams.plan ? viewParams.plan.planName : 'FPL Manager Activities',
        html
      );
    };
    return (
      <TabPane
        className="fpl-grid-top"
        tabId={Constants.FPL_MANAGER_ACTIVITIES}>
        <p></p>
        <Row className="fpl-grid-top justify-content-end">
          <Col lg={2} className="text-right downloadCard">
            <Button
              id="downloadPdfButton"
              color="secondary"
              className="text-nowrap mx-auto downloadButtom"
              onClick={print}>
              Download PDF
            </Button>
          </Col>
        </Row>
      </TabPane>
    );
  };

  renderEvestStrategy = () => {
    const whenEmpty = (
      <TabPane tabId={Constants.FPL_EVEST_NARRATIVES}>
        <span className="font-italic">
          EVestment does not provide any information for this strategy.
        </span>
      </TabPane>
    );
    return (
      <>
        {Boolean(this.props.evestStrategy.loading) && (
          <div className="loading-container">
            <LoadingComponentOverlay />
          </div>
        )}
        {Boolean(!this.props.evestStrategy.loading) && (
          <>
            <TabPane tabId={Constants.FPL_EVEST_NARRATIVES}>
              <Row>
                <Col md={12} className="d-flex strategyDownloadDetail">
                  <span className="detailTitle" />
                  {this.renderPrintEVestmentNarratives()}
                </Col>
              </Row>
              <Row className="fpl-nepc-eVestment">
                <Col>
                  <ContentFields
                    layout={eVestStrategyLayout}
                    data={this.props.evestStrategy.data}
                    whenEmpty={whenEmpty}
                  />
                  <br />
                  <span className="fpl-evest-source">Source: eVestment.</span>
                </Col>
              </Row>
            </TabPane>
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <Row>
        <Col className="mt-3" md={12}>
          {this.renderTabs()}
        </Col>
        <Col md={12}>{this.renderContents()}</Col>
      </Row>
    );
  }
}

StrategyContents.propTypes = {
  viewParams: PropTypes.object,
  documentView: PropTypes.string,
  strategy: PropTypes.object,
  strategyUrl: PropTypes.string,

  printNepcOpnion: PropTypes.func.isRequired,
  printPortfolio: PropTypes.func,
  printEVestmentNarratives: PropTypes.func.isRequired,
  loadFPLeVest: PropTypes.func.isRequired,
  planManagerActivities: PropTypes.array,

  user: PropTypes.object,

  showWarText: PropTypes.func,
  nepcStrategy: PropTypes.object,
  evestStrategy: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    okta: { user },
  } = state;
  return {
    planManagerActivities: state.resources.fpl_manager_activities.data || [],
    nepcStrategy: state.resources.fpl_nepc_opinion.data || {},
    evestStrategy: state.resources.fpl_evest_narratives || {},
    user,
  };
};

export default connect(mapStateToProps, {
  printNepcOpnion,
  printEVestmentNarratives,
  loadFPLeVest,
  printPortfolio,
})(StrategyContents);
