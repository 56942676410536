import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import _ from 'lodash';

import ContentFields from './ContentFields';

export class NotesModal extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({});
    }
  }

  print = () => {
    this.props.showAffectedPlans(false);
    this.props.showWarText(false);
    setTimeout(() => {
      const elem = document.querySelector('.meetingNotesDetail');
      const modalHeader = document.querySelector('.modal-header').outerHTML;
      const modalFooter = document.querySelector('.modal-footer').outerHTML;
      let html = elem.parentElement.innerHTML;
      html = html.replace(modalHeader, '');
      html = html.replace(modalFooter, '');
      const sendedData = {
        userMail: this.props.user.email,
        subject: this.props.data.subject || this.props.data.eventTitle,
        documentType: this.props.documentType,
      };
      this.props.printDocument(sendedData, this.props.title, html);
      this.props.showAffectedPlans(true);
      this.props.showWarText(true);
    });
  };

  renderHeader(data) {
    return _.map(this.props.headerLayout, (row, rowIndex) => {
      const cols = _.map(row, (item, index) => {
        if (!item) return <Col key={index} />;
        const identity = id => id;
        const formatter = item.formatter || identity;
        const value = formatter(_.get(data, item.field, ''));
        return (
          <Col key={item.field}>
            <span className="detailTitle"> {item.title}: </span>
            {value}
          </Col>
        );
      });
      return <Row key={rowIndex}>{cols}</Row>;
    });
  }

  renderContent(data) {
    return (
      <div className="notesModalArea">
        <ContentFields
          layout={this.props.contentLayout}
          data={data}
          whenEmpty={this.props.whenEmpty}
        />
      </div>
    );
  }

  renderDisclaimer() {
    return this.props.disclaimer;
  }

  renderPrint() {
    if (!this.props.printable) {
      return null;
    }
    return (
      <Button color="secondary" className="text-nowrap" onClick={this.print}>
        Download PDF
      </Button>
    );
  }

  renderTitle() {
    return (
      <>
        <div>{this.props.title}</div>
        <div>{this.renderPrint()}</div>
      </>
    );
  }

  render() {
    const { data, toggle } = this.props;
    const visible = data !== null && Object.keys(data).length > 0;
    if (!visible) return null;
    return (
      <Modal
        isOpen={true}
        toggle={toggle}
        size="xl"
        className="meetingNotesDetail">
        {/* <div className="printOnly documentType">{this.props.documentType}</div> */}
        <ModalHeader toggle={toggle}>{this.renderTitle()}</ModalHeader>
        <ModalBody>
          {this.renderDisclaimer()}
          {this.renderHeader(data)}
          <hr />
          {this.renderContent(data)}
        </ModalBody>
        <ModalFooter>
          {this.renderPrint()}
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

NotesModal.propTypes = {
  printable: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  printDocument: PropTypes.func,
  contentLayout: PropTypes.array.isRequired,
  headerLayout: PropTypes.array.isRequired,
  disclaimer: PropTypes.element,
  whenEmpty: PropTypes.element,
  user: PropTypes.object,

  data: PropTypes.object,
  title: PropTypes.string,
  documentType: PropTypes.string,
  showAffectedPlans: PropTypes.func,
  showWarText: PropTypes.func,
};

export default NotesModal;
