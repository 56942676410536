import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Button } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';

import {
  savePlanUserActivationsAndLoad,
  loadPlanAdminUserList,
  openConfirmationModal,
} from '../../../actions';
import AdminColumnDefs from './AdminColumnDefs';
import ConfirmationModal from '../../ConfirmationModal';
import LoadingComponentOverlay from '../../LoadingSpinner';

export class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = this.loadUsers(props.users);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.users !== nextProps.users) {
      this.setState(this.loadUsers(nextProps.users));
    }
  }

  loadUsers = (users) => {
    const rowData = _.map(users, (user) => ({
      ...user,
      accessEnabledEdit: user.accessEnabled,
      disabled: user.email === this.props.adminEmail,
      onClick: this.handleClick(user),
    }));
    return { rowData };
  };

  handleSave = () => {
    this.props.savePlanUserActivationsAndLoad(
      this.state.rowData,
      this.props.planId
    );
  };

  handleSaveValidation = () => {
    const allowed = this.state.rowData.filter((i) => i.accessEnabledEdit);
    if (allowed.length > 0) {
      this.handleSave();
    } else {
      this.props.openConfirmationModal(true);
    }
  };

  handleClick = (user) => (e) => {
    const users = _.map(this.state.rowData, (rowUser) => {
      if (user.contactId === rowUser.contactId) {
        return { ...rowUser, accessEnabledEdit: !rowUser.accessEnabledEdit };
      }
      return { ...rowUser };
    });
    this.setState({ rowData: users });
  };

  hasUnsavedChanges = () =>
    _.some(
      this.state.rowData,
      (user) => user.accessEnabledEdit !== user.accessEnabled
    );

  handleConfirm = () => {
    this.props.openConfirmationModal(false);
    this.handleSave();
  };

  render() {
    if (this.props.planId) {
      this.props.loadPlanAdminUserList(this.props.planId, false);
    }
    const canSave = !this.hasUnsavedChanges();
    return (
      <div className="admin">
        <p>Check off the users to enable and Click Save.</p>
        <Row>
          <div className="userListGrid mt-3 mb-3 ag-theme-balham">
            <AgGridReact
              columnDefs={AdminColumnDefs}
              rowData={this.state.rowData}
              suppressScrollOnNewData={true}
              suppressDragLeaveHidesColumns={true}
              components={{ agLoadingOverlay: LoadingComponentOverlay }}
            />
          </div>
        </Row>
        <Row>
          <Button
            color="primary"
            onClick={this.handleSaveValidation}
            disabled={canSave}>
            Save
          </Button>
        </Row>
        <ConfirmationModal
          message="You have not enabled anyone else to access this plan."
          message2="If you continue, you will be the only person with access to this plan."
          title="Admin validation"
          confirm={this.handleConfirm}
        />
      </div>
    );
  }
}

Admin.propTypes = {
  users: PropTypes.array,
  adminEmail: PropTypes.string.isRequired,
  savePlanUserActivationsAndLoad: PropTypes.func.isRequired,
  loadPlanAdminUserList: PropTypes.func.isRequired,
  adminSavedSuccessfully: PropTypes.bool.isRequired,
  planId: PropTypes.string,
  openConfirmationModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    users: state.resources.plan_admin_userlist.data || [],
    adminEmail:
      _.get(state, 'ui.selectedPlan.planAdministratorEmail', '') || '',
    planId: _.get(state, 'ui.selectedPlan.planId') || '',
    adminSavedSuccessfully: state.ui.adminSavedSuccessfully,
  };
};

export default connect(mapStateToProps, {
  savePlanUserActivationsAndLoad,
  loadPlanAdminUserList,
  openConfirmationModal,
})(Admin);
