import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Button, Input, FormGroup, FormFeedback } from 'reactstrap';

import { changePassword, resetChangePassword } from './actions';
import { redirect } from '../../actions';
import AuthContainer from '../login/AuthContainer';

const SUCCESS_REDIRECT_AFTER_X_MS = 5000;

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        oldPassword: { value: '', valid: false, error: null, showError: false },
        newPassword: { value: '', valid: false, error: null, showError: false },
        newPassword2: {
          value: '',
          valid: false,
          error: null,
          showError: false,
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.success && prevProps.success !== this.props.success) {
      setTimeout(this.postSuccessRedirect, SUCCESS_REDIRECT_AFTER_X_MS);
    }
  }

  postSuccessRedirect = () => {
    this.props.redirect('/profile');
    this.props.resetChangePassword();
  };

  allValid = () => {
    return !_.some(this.state.fields, field => !field.valid);
  };

  handleChangePassword = () => {
    if (!this.props.loading && this.allValid()) {
      const { oldPassword, newPassword } = this.state.fields;
      this.props.changePassword(oldPassword.value, newPassword.value);
    }
  };

  handleCancel = () => {
    this.props.redirect('/');
  };

  handleChange = (field, validator) => event => {
    const value = event.target.value;
    let valid = true;
    let error = null;
    if (validator) {
      error = validator(value);
      valid = error === null;
    }
    const fieldData = { ...this.state.fields[field], value, valid, error };
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: fieldData,
      },
    });
  };

  showErrors = field => () => {
    if (!this.state.fields[field].value) {
      // No value? Ok, no errors.
      return;
    }
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: { ...this.state.fields[field], showError: true },
      },
    });
  };

  createInputRenderer = (title, field, validator = null, inputProps = {}) => {
    const handleChange = this.handleChange(field, validator);
    const handleBlur = this.showErrors(field);
    const { showError, valid, value, error } = this.state.fields[field];
    return (
      <Row>
        <FormGroup>
          <Input
            type="text"
            placeholder={title}
            invalid={showError && !valid}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...inputProps}
          />
          <FormFeedback>{error}</FormFeedback>
        </FormGroup>
      </Row>
    );
  };

  createPasswordInputRenderer = (title, field, validator = null) =>
    this.createInputRenderer(
      title,
      field,
      value => {
        if (value.length < 3) {
          return 'Please, type your ' + title.toLowerCase() + '.';
        }
        return validator && validator(value);
      },
      { type: 'password' }
    );

  renderCurrentPassword = this.createPasswordInputRenderer(
    'Current Password',
    'oldPassword'
  );

  renderNewPassword = this.createPasswordInputRenderer(
    'New Password',
    'newPassword'
  );

  renderNewPasswordConfirmation = this.createPasswordInputRenderer(
    'Confirm new Password',
    'newPassword2',
    newPassword2 => {
      if (newPassword2 !== this.state.fields.newPassword.value) {
        return 'Does not match';
      }
      return null;
    }
  );

  renderStatus() {
    if (this.props.error) {
      return <span>{this.props.error}</span>;
    }
    return null;
  }

  renderSubmit() {
    const title = this.props.success ? 'SUCCESS' : 'Change Password';
    const onClick = this.props.success
      ? this.postSuccessRedirect
      : this.handleChangePassword;
    return (
      <Row>
        <FormGroup>
          <Button
            className="authContainer-btn mt-2"
            color="success"
            onClick={onClick}>
            {title}
          </Button>
        </FormGroup>
      </Row>
    );
  }

  renderCancel() {
    if (this.props.success) {
      return null;
    }
    return (
      <Row>
        <FormGroup>
          <Button
            className="authContainer-btn mt-2"
            color="secondary"
            onClick={this.handleCancel}>
            Cancel
          </Button>
        </FormGroup>
      </Row>
    );
  }

  renderChangePasswordForm() {
    return (
      <Fragment>
        {this.renderCurrentPassword()}
        {this.renderNewPassword()}
        {this.renderNewPasswordConfirmation()}
        {this.renderStatus()}
        {this.renderSubmit()}
        {this.renderCancel()}
      </Fragment>
    );
  }

  render() {
    return <AuthContainer>{this.renderChangePasswordForm()}</AuthContainer>;
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  resetChangePassword: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  // Status
  success: PropTypes.bool.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    success: state.changePassword.success,
    error: state.changePassword.error,
    loading: state.ui.isLoading,
  };
};
export default connect(mapStateToProps, {
  changePassword,
  resetChangePassword,
  redirect,
})(ChangePassword);
