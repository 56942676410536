import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Constants from '../../services/Constants';

class VideosRouter extends Component {
  onVideos = (categories, topics) => {
    const vp = { categories, topics };
    return this.props.withMain(Constants.VIDEOS, vp);
  };

  render() {
    const { match, categories, topics } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.url}/`}
          render={() => this.onVideos(categories, topics)}
        />
      </Switch>
    );
  }
}

VideosRouter.propTypes = {
  match: PropTypes.object.isRequired,
  withMain: PropTypes.func,
  categories: PropTypes.string.isRequired,
  topics: PropTypes.string.isRequired,
};

export default VideosRouter;
