import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';

import _ from 'lodash';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Container from 'reactstrap/lib/Container';

import {
  loadNotificationFilter,
  sendNotificationTest,
  updateNotificationsFilter,
  sendNotificationEmployees,
  sendNotificationClients,
} from '../../actions';
import { RunNotificationEmployeesModal } from './RunNotificationModalEmployees';
import { RunNotificationClientModal } from './RunNotificationClientModal';

export class NotificationsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: {},
      isSendingTest: false,
      showDangerModalClients: false,
      showDangerModalEmployees: false,
    };
    this.toggleDangerModalClients = this.toggleDangerModalClients.bind(this);
    this.toggleDangerModalEmployees =
      this.toggleDangerModalEmployees.bind(this);
  }

  toggleDangerModalClients() {
    this.setState({
      showDangerModalClients: !this.state.showDangerModalClients,
      showDangerModalEmployees: false,
    });
  }

  toggleDangerModalEmployees() {
    this.setState({
      showDangerModalEmployees: !this.state.showDangerModalEmployees,
      showDangerModalClients: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.persistPropsOnState(nextProps);
  }

  persistPropsOnState = (p) => {
    this.setState({
      notifications: p.notifications,
    });
  };

  defaultGroupHasTemplateId = (group, groupTittle) => {
    if (group.meetingNotes.enabled || group.ddEvents.enabled) {
      if (group.templateId) {
        return true;
      }
      NotificationManager.error(
        `Missing template id for ${groupTittle} field`,
        ''
      );
      return false;
    }
    return true;
  };

  controlGroupHasTemplateId = (controlGroupType, groupType, groupTittle) => {
    if (
      controlGroupType.meetingNotes.enabled ||
      controlGroupType.ddEvents.enabled
    ) {
      if (controlGroupType.controlMail) {
        if (controlGroupType.templateId || groupType.templateId) {
          return true;
        }
        NotificationManager.error(
          `Missing template id for ${groupTittle} control field`,
          ''
        );
        return false;
      }
      NotificationManager.error(
        `Missing email for ${groupTittle} control field`,
        ''
      );
      return false;
    }
    return true;
  };

  hasTemplateId = (groupType, controlGroupType, groupTittle) => {
    return (
      this.defaultGroupHasTemplateId(groupType, `${groupTittle}`) &&
      this.controlGroupHasTemplateId(
        controlGroupType,
        groupType,
        `${groupTittle}`
      )
    );
  };

  testTemplateId = () => {
    const { notifications } = this.state;
    if (notifications.testGroup.templateId) {
      return notifications.testGroup.templateId;
    }
    if (this.isEmployee(notifications.testGroup.mail)) {
      if (
        notifications.employeeGroup.templateId ||
        notifications.employeeControlGroup.templateId
      ) {
        return (
          notifications.employeeGroup.templateId ||
          notifications.employeeControlGroup.templateId
        );
      }
      NotificationManager.error(
        'Missing employee template id or test template id',
        ''
      );
      return null;
    } else if (
      notifications.clientGroup.templateId ||
      notifications.clientControlGroup.templateId
    ) {
      return (
        notifications.clientGroup.templateId ||
        notifications.clientControlGroup.templateId
      );
    }
    NotificationManager.error(
      'Missing client template id or test template id',
      ''
    );
    return null;
  };

  saveValues = () => {
    const { notifications } = this.state;
    if (
      this.hasTemplateId(
        notifications.clientGroup,
        notifications.clientControlGroup,
        `client`
      ) &&
      this.hasTemplateId(
        notifications.employeeGroup,
        notifications.employeeControlGroup,
        `employee`
      )
    ) {
      return this.props.updateNotificationsFilter(notifications);
    }
  };

  isEmployee = (email) => {
    return email.includes('@nepc.com');
  };

  onSendTest = () => {
    const templateIdValue = this.testTemplateId();
    const notifications = {
      ...this.state.notifications,
      testGroup: {
        ...this.state.notifications.testGroup,
        templateId: templateIdValue,
      },
    };
    this.setState({
      isSendingTest: true,
    });
    this.props
      .sendNotificationTest(notifications)
      .then((resp) => {
        this.setState({
          isSendingTest: false,
        });
        if (resp.status === 204) {
          NotificationManager.warning(
            'There are no Meeting Notes nor DDEvents to send mail notification for this configuration',
            ''
          );
        } else {
          NotificationManager.success('Success sending test notification', '');
        }
      })
      .catch((e) => {
        this.setState({
          isSendingTest: false,
        });
        NotificationManager.error('Error sending test notification', '');
      });
  };

  renderInput = (field, tittle) => {
    const onChangeCheck = (e) => {
      const fieldName = e.target.name;
      field.enabled = e.target.checked;
      if (field.enabled) {
        field.date = moment().subtract(7, 'days').format('YYYY-MM-DD');
      } else {
        field.date = null;
      }
      this.setState({
        notifications: {
          ...this.state.notifications,
          [fieldName]: {
            ...this.state.notifications[fieldName],
            [tittle]: field,
          },
        },
      });
    };

    const onChangeDate = (e) => {
      const fieldName = e.target.name;
      field.date = e.target.value;
      this.setState({
        notifications: {
          ...this.state.notifications,
          [fieldName]: {
            ...this.state.notifications[fieldName],
            [tittle]: field,
          },
        },
      });
    };

    if (
      tittle === 'ddEvents' ||
      tittle === 'meetingNotes' ||
      tittle === 'researchDocuments'
    ) {
      return (
        <Col key={`${tittle}-${field}`} className="fullInput">
          <InputGroup>
            {/* <InputGroupAddon addonType="prepend"> */}
            <InputGroupText>
              <Input
                className="custom-checkbox"
                name={tittle}
                type="checkbox"
                onChange={onChangeCheck}
                checked={field.enabled}
              />
            </InputGroupText>
            {/* </InputGroupAddon> */}
            <Input
              name={tittle}
              value={field.date ? field.date : ''}
              disabled={!field.enabled}
              type="date"
              onChange={onChangeDate}
              className="fromDate"
            />
          </InputGroup>
        </Col>
      );
    }
    return null;
  };

  labelType = (fieldName, fieldTittle) => {
    if (fieldName.includes('control')) {
      return (
        <Input
          name={fieldTittle}
          value={
            this.state.notifications[fieldTittle].controlMail
              ? this.state.notifications[fieldTittle].controlMail
              : ''
          }
          type="Text"
          placeholder={fieldName}
          onChange={this.onChangeControlMail}
          className="inputLabel"
        />
      );
    }
    return <Label className="lineLabel text-nowrap">{fieldName}</Label>;
  };

  renderInputs = (lineTittle, field, fieldTittle) => {
    const templateIdValue = field?.templateId ? field.templateId : undefined;
    return (
      <Row key={`${field}-${fieldTittle}`} className="inputLine">
        <div className="tittle">{this.labelType(lineTittle, fieldTittle)}</div>
        {_.map(field, (row, rowIndex, index) => {
          const fieldName = rowIndex;
          return this.renderInput(row, fieldName, index);
        })}
        <Col>
          <Input
            name={fieldTittle}
            value={templateIdValue}
            type="text"
            onChange={this.onChange}
            className="templateInput"
            placeholder="TemplateId"
          />
        </Col>
      </Row>
    );
  };

  onChangeControlMail = (e) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    return this.setState({
      notifications: {
        ...this.state.notifications,
        [fieldName]: {
          ...this.state.notifications[fieldName],
          controlMail: fieldValue,
        },
      },
    });
  };

  onChange = (e) => {
    const field = e.target.value;
    const fieldName = e.target.name;
    let filteredField = field.replace(/[^0-9]/g, '');
    if (filteredField === '') {
      filteredField = null;
    }
    return this.setState({
      notifications: {
        ...this.state.notifications,
        [fieldName]: {
          ...this.state.notifications[fieldName],
          templateId: filteredField,
        },
      },
    });
  };

  onChangeTestMail = (e) => {
    const field = e.target.value;
    const fieldName = e.target.name;
    return this.setState({
      notifications: {
        ...this.state.notifications,
        testGroup: {
          ...this.state.notifications.testGroup,
          [fieldName]: field,
        },
      },
    });
  };

  validateTestMailFields = () => {
    return (
      this.state.notifications.testGroup.mail === '' ||
      this.state.notifications.testGroup.mailTo === '' ||
      this.state.notifications.testGroup.mail === null ||
      this.state.notifications.testGroup.mailTo === null
    );
  };

  validateTestDateFields = () => {
    return (
      this.state.notifications.testGroup.ddEvents.enabled === false &&
      this.state.notifications.testGroup.researchDocuments.enabled === false &&
      this.state.notifications.testGroup.meetingNotes.enabled === false
    );
  };

  disabledTest = () => {
    return (
      this.validateTestMailFields() ||
      this.validateTestDateFields() ||
      this.state.isSendingTest
    );
  };

  renderTestGroup = () => {
    const { notifications } = this.state;
    return (
      <div className="testGroup">
        <div className="line" />
        <div className="fromDateRow">
          <div className="dateHead">from date:</div>
          <div className="dateHead">from date:</div>
          <div className="dateHead">from date:</div>
        </div>
        {this.renderInputs(
          'Test notifications: ',
          notifications.testGroup,
          'testGroup'
        )}
        <div className="emailGroup">
          <div>
            <Label className="emailLabel text-nowrap">
              Simulation address:
            </Label>
            <Input
              name="mail"
              placeholder="Simulation address"
              className="emailInput"
              value={
                this.state.notifications.testGroup.mail
                  ? this.state.notifications.testGroup.mail
                  : ''
              }
              onChange={this.onChangeTestMail}
            />
            <div className="sended-message">
              Test will use the correct template from above (employee or client)
              unless explicitly overwritten
            </div>
          </div>
          <div>
            <Label className="emailLabel text-nowrap">
              Send email address:
            </Label>
            <Input
              name="mailTo"
              placeholder="Send email address"
              className="emailInput"
              value={
                this.state.notifications.testGroup.mailTo
                  ? this.state.notifications.testGroup.mailTo
                  : ''
              }
              onChange={this.onChangeTestMail}
            />
            <Button
              className="testButton text-nowrap"
              disabled={this.disabledTest()}
              onClick={this.onSendTest}>
              Send test
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderForm = () => {
    const { notifications } = this.props;

    if (
      this.props.notifications &&
      this.state.notifications &&
      !this.state.notifications.clientGroup
    ) {
      this.persistPropsOnState(this.props);
      return null;
    }

    return (
      <div className="admin">
        <RunNotificationEmployeesModal {...this} />
        <RunNotificationClientModal {...this} />
        <Container className="notificationContainer">
          <Form>
            <div className="border">
              <div className="card-header">
                <div className="headers text-nowrap">
                  <div>Activate for:</div>
                  <div className="managerHead">Manager Writeups:</div>
                  <div className="ddEventHead">DD Events:</div>
                  <div className="researchDocuments">Research documents:</div>
                  <div className="templateHead">Template Id:</div>
                </div>
              </div>
              {Boolean(notifications) && (
                <div>
                  <div className="fromDateRow">
                    <div className="dateHead">from date:</div>
                    <div className="dateHead">from date:</div>
                    <div className="dateHead">from date:</div>
                  </div>
                  <FormGroup className="form">
                    <Row className="align-items-center flex-nowrap">
                      <Col>
                        {this.renderInputs(
                          'Client notifications: ',
                          this.state.notifications.clientGroup,
                          'clientGroup'
                        )}
                        {this.renderInputs(
                          'Client control mail',
                          this.state.notifications.clientControlGroup,
                          'clientControlGroup'
                        )}
                      </Col>
                      <Col>
                        <Button
                          color="danger"
                          onClick={this.toggleDangerModalClients}>
                          <i className="fa-solid fa-play"></i>
                        </Button>
                      </Col>
                    </Row>
                    <div className="line" />
                    <Row className="align-items-center flex-nowrap">
                      <Col>
                        {this.renderInputs(
                          'Employee notifications: ',
                          this.state.notifications.employeeGroup,
                          'employeeGroup'
                        )}
                        {this.renderInputs(
                          'Employee control mail',
                          this.state.notifications.employeeControlGroup,
                          'employeeControlGroup'
                        )}
                      </Col>
                      <Col>
                        <Button
                          color="danger"
                          onClick={this.toggleDangerModalEmployees}>
                          <i className="fa-solid fa-play"></i>
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  {this.renderTestGroup()}
                  <div className="line" />
                  <FormGroup row>
                    <Col>
                      <Button
                        color="primary"
                        className="savebutton"
                        onClick={this.saveValues}>
                        Save
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
              )}
            </div>
          </Form>
        </Container>
      </div>
    );
  };

  render() {
    setTimeout(() => this.props.loadNotificationFilter());

    return this.renderForm();
  }
}

NotificationsAdmin.propTypes = {
  notifications: PropTypes.object,
  loadNotificationFilter: PropTypes.func.isRequired,
  updateNotificationsFilter: PropTypes.func,
  sendNotificationTest: PropTypes.func,
  sendNotificationEmployees: PropTypes.func,
  sendNotificationClients: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    notifications: state.resources.notifications.data,
  };
};

export default connect(mapStateToProps, {
  loadNotificationFilter,
  updateNotificationsFilter,
  sendNotificationTest,
  sendNotificationEmployees,
  sendNotificationClients,
})(NotificationsAdmin);
