import propTypes from 'prop-types';
import React from 'react';

const Videos = props => {
  const {
    viewParams: { topics, categories },
  } = props;
  return (
    <iframe
      title="videos"
      className="content-frame"
      src={
        'https://www.nepc.com/videos/?_topics_of_interest=' +
        topics +
        '&_categories=' +
        categories
      }
      frameBorder="0"
      allowFullScreen
    />
  );
};

Videos.propTypes = {
  viewParams: propTypes.object.isRequired,
};

export default Videos;
