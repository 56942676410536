import _ from 'lodash';

export const textComparator = (value1, value2) => {
  // leave the null values as first in the list
  if (!value1) {
    return 1;
  }
  if (!value2) {
    return -1;
  }

  if (value1 instanceof Array || value2 instanceof Array) {
    return _.toUpper(value1).localeCompare(_.toUpper(value2));
  }

  const a = value1.replace(',', '');
  const b = value2.replace(',', '');
  return _.toUpper(a).localeCompare(_.toUpper(b));
};

export const textColumn = {
  comparator: textComparator,
  valueFormatter: data => data.value,
};

export const equalsIgnoreCase = (value1, value2) => {
  return textComparator(value1, value2) === 0;
};
