import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { formatCurrency } from '../../../services/NumberService';
import LoadingComponentOverlay from '../../LoadingSpinner';
import viewParams from '../../router/ViewParams';

export class DocumentsGridTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reloaded: false,
      groupRowRendererParams: {
        innerRenderer: params => (
          <>
            <div>{params.value}</div>
            {Boolean(
              params.node.aggData && Object.keys(params.node.aggData).length > 0
            ) && (
              <div>
                {formatCurrency(
                  params.node.aggData[Object.keys(params.node.aggData)[0]]
                )}
              </div>
            )}
          </>
        ),
        suppressCount: true,
      },
    };
    this.columnApi = null;
    this.gridApi = null;
    this.gridRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.filterValue !== nextProps.filterValue) {
      this.updateFilters();
    }
    if (this.props.columnDefs !== nextProps.columnDefs) {
      this.sizeColumnsToFit();
    }
    if (
      (this.props.rowData !== nextProps.rowData ||
        this.props.noRowsMessage !== nextProps.noRowsMessage) &&
      !this.gridApi?.isDestroyed()
    ) {
      this.refreshNoRows();
    }
  }

  refreshNoRows = _.debounce(() => {
    if (!this.gridApi) {
      return this.refreshNoRows();
    }
    if (
      this.getRowData().length === 0 &&
      this.props.hasLoaded &&
      !this.gridApi?.isDestroyed()
    ) {
      this.gridApi.showNoRowsOverlay();
    }
  }, 1);

  updateFilters = _.debounce(() => this.onFilterChanged(), 100);

  onFilterChanged = () => {
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    } else {
      this.updateFilters();
    }
  };

  expandAll = () => {
    this.gridRef.current.api.expandAll();
    this.gridRef.current.api.applyColumnState({
      state: [{ colId: 'strategyName', sort: 'asc', sortIndex: 0 }],
      defaultState: {
        sort: null,
      },
    });
  };

  collapseAll = () => {
    this.gridRef.current.api.collapseAll();
  };

  exportToCsv = fileName => {
    this.gridRef.current.api.exportDataAsCsv({ fileName });
  };

  setColumnGridApiAndApplySizeColumnsToFit = ({ columnApi, api }) => {
    this.columnApi = columnApi;
    this.gridApi = api;
    this.sizeColumnsToFit();
    const colState = this.gridRef.current.api.getColumnState();
    const sortModel = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
      this.saveSortState(sortModel);
  };

  saveSortState = sortModel => {
    if (this.props.saveSortState) {
      this.props.saveSortState(sortModel);
    }
  };

  onGridReady = ({ columnApi, api }) => {
    this.setColumnGridApiAndApplySizeColumnsToFit({ columnApi, api });
  };

  firstDataRendered = ({ columnApi, api }) => {
    this.setColumnGridApiAndApplySizeColumnsToFit({ columnApi, api });
  };

  // Note: return true to keep item, false to hide
  shouldFilterRow = node => {
    const { filterValue } = this.props;
    if (filterValue) {
      const properties = Object.keys(filterValue);
      let shouldReturnRow = true;
      properties.forEach(item => {
        if (shouldReturnRow) {
          if (Array.isArray(node.data[item])) {
            shouldReturnRow =
              !filterValue[item] ||
              filterValue[item].includes('All') ||
              node.data[item].some(filter =>
                filterValue[item].includes(filter)
              );
          } else if (item === 'investmentFirmName' || item === 'firm') {
            shouldReturnRow =
              !filterValue[item] ||
              node.data[item].toLowerCase().includes(filterValue[item]);
          } else {
            shouldReturnRow =
              !filterValue[item] ||
              filterValue[item].includes('All') ||
              filterValue[item].includes(node.data[item]);
          }
        }
      });
      return shouldReturnRow;
    }
    return true;
  };

  onGridSizeChanged = () => {
    this.sizeColumnsToFit();
  };

  onSortChanged = event => {
    const sortModel = event.columns
    .filter(function (s) {
      return s.sort != null;
    })
    .map(function (s) {
      return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
    });
    this.saveSortState(sortModel);
  };

  sizeColumnsToFit = () => {
    const poll = _.debounce(() => {
        this.gridApi.sizeColumnsToFit();
        return;
    }, 100);
    poll();
  };

  getRowData = () => {
    const { filterValue, gridContext } = this.props;
    if (
      gridContext === 'all_managers' &&
      (!filterValue?.betaGroup || filterValue?.betaGroup[0] === 'All')
    ) {
      return [];
    }
    return this.props.rowData.sort((a, b) => {
      return a.assetTypeSortOrder - b.assetTypeSortOrder;
    });
  };

  render() {
    const gridOptions = {
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: this.shouldFilterRow,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        suppressHeaderFilterButton: true,
        suppressHeaderMenuButton: true
      },
      context: {
        ...this.props.context,
        viewParams: this.props.viewParams,
      },
      reactiveCustomComponents: true,
    };
    const getRowClass = params => `ag-custom-rowindex-${params.node.rowIndex}`;
    const getRowHeight = () => this.props.rowHeight | 30;
    const rowData = this.getRowData();
    return (
      <div
        className={`gridArea ag-theme-alpine mt-3 mb-3 ${this.props.className}`}>
        <AgGridReact
          gridOptions={gridOptions}
          loading={this.gridApi && !this.props.hasLoaded && !this.gridApi?.isDestroyed()}
          columnDefs={this.props.columnDefs}
          rowData={rowData}
          onGridReady={this.onGridReady}
          onSortChanged={this.onSortChanged}
          onFilterChanged={this.props.onCustomFilterRow}
          onFirstDataRendered={this.firstDataRendered}
          pagination={this.props.pagination}
          components={{ agLoadingOverlay: LoadingComponentOverlay, NoRowsOverlay: this.props.noRowsMessage }}
          onGridSizeChanged={this.onGridSizeChanged}
          getRowClass={getRowClass}
          rowHeight={getRowHeight()}
          headerHeight={this.props.headerHeight ?? 30}
          suppressMenuHide={true}
          groupRowRendererParams={this.state.groupRowRendererParams}
          groupDefaultExpanded={this.props.groupDefaultExpanded}
          groupDisplayType={'groupRows'}
          groupTotalRow={false}
          grandTotalRow={false}
          suppressAggFuncInHeader={true}
          animateRows={true}
          suppressDragLeaveHidesColumns={true}
          ref={this.gridRef}
          defaultState
        />
      </div>
    );
  }
}

DocumentsGridTree.propTypes = {
  viewParams,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.array.isRequired,
  noRowsMessage: PropTypes.string.isRequired,
  saveSortState: PropTypes.func,
  filterProperty: PropTypes.array,
  filterValue: PropTypes.object,
  className: PropTypes.string,
  rowHeight: PropTypes.number,
  onCustomFilterRow: PropTypes.func,
  context: PropTypes.object,
  pagination: PropTypes.bool,
  hasLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  gridContext: PropTypes.string,
  groupDefaultExpanded: PropTypes.number,
  getDataPath: PropTypes.func,
  autoGroupColumnDef: PropTypes.object,
  headerHeight: PropTypes.number,
};

export default DocumentsGridTree;
