import React, { Component } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CustomInput,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Disclaimer from '../../../components/Disclaimer';
import AllPlans from './AllPlans';
import MyPlans from './MyPlans';
import { saveHideUnpublishedPlans } from '../../../actions';
import {
  Storages,
  itemExists,
  getFromLocalStorage,
} from '../../../services/LocalStorageService';

const MY_PLANS_TAB = '1';
const ALL_PLANS_TAB = '2';

export class EmployeePlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: MY_PLANS_TAB,
    };
    this.manageLocalStorage();
  }

  toggle = index => {
    this.setState({ activeTab: index });
  };

  hideUnpublishedPlans = e => {
    this.props.saveHideUnpublishedPlans(e.target.checked);
  };

  manageLocalStorage = () => {
    if (!itemExists(Storages.HIDE_UNPUBLISHED_PLANS)) {
      return this.props.saveHideUnpublishedPlans(false);
    }
    this.props.saveHideUnpublishedPlans(
      getFromLocalStorage(Storages.HIDE_UNPUBLISHED_PLANS)
    );
    this.render();
  };

  render() {
    const selected = this.state.activeTab;
    const myPlansTabClass = selected === MY_PLANS_TAB ? 'active' : '';
    const allPlansTabClass = selected === ALL_PLANS_TAB ? 'active' : '';

    return (
      <div className="employeePlans">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={myPlansTabClass}
              href="#"
              onClick={() => {
                this.toggle(MY_PLANS_TAB);
              }}>
              My Portfolios
            </NavLink>
          </NavItem>
          <NavItem id="all-portfolios-nav-item">
            <NavLink
              className={allPlansTabClass}
              href="#"
              onClick={() => {
                this.toggle(ALL_PLANS_TAB);
              }}>
              All Portfolios
            </NavLink>
          </NavItem>
          <CustomInput
            name={Storages.HIDE_UNPUBLISHED_PLANS}
            id="hideUnpublishedPlansSwitch"
            type="switch"
            checked={this.props.hideUnpublished}
            className="switch"
            onChange={e => this.hideUnpublishedPlans(e)}
            label="Hide unpublished plans"
          />
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={MY_PLANS_TAB}>
            <Disclaimer
              id="myPortfolioMessage"
              name="myPortfolioMessage col-lg-5">
              <ul>
                <li>
                  <b>Published</b> portfolios have an assigned ClientHUB
                  Administrator in Backstop and are visible to clients with
                  permission to access them.
                </li>
                <li>
                  <b>Unpublished</b> portfolios do not have a ClientHUB
                  Administrator in Backstop and will not show for any clients
                  until the ClientHUB Administrator is defined in Backstop.
                </li>
                <li>
                  You can <b>Hide Unpublished Portfolios</b> from this view
                  using the toggle at the top of this page.
                </li>
                <li>
                  See{' '}
                  <a href="https://central.nepc.com/x/OhZwDw">
                    ClientHUB Set-Up Checklist
                  </a>{' '}
                  for more details on configuring the client experience.
                </li>
              </ul>
            </Disclaimer>
            <MyPlans plans={this.props.plans} isMyPlans={true} />
          </TabPane>

          <TabPane tabId={ALL_PLANS_TAB}>
            <AllPlans
              plansByLetter={this.props.plansByLetter}
              requestPlansForLetter={this.props.requestPlansForLetter}
              hideUnpublished={this.props.hideUnpublished}
              emptyLetter={this.props.emptyLetter}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

EmployeePlans.propTypes = {
  plans: PropTypes.array.isRequired,
  plansByLetter: PropTypes.object,
  requestPlansForLetter: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  hideUnpublished: PropTypes.bool,
  saveHideUnpublishedPlans: PropTypes.func,
  emptyLetter: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    hideUnpublished: state.ui.hideUnpublished,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    saveHideUnpublishedPlans: (...a) =>
      dispatch(saveHideUnpublishedPlans(...a)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePlans);
