import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePdf } from '@mikecousins/react-pdf';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import {
  openMarketUpdates,
  openDocument,
  loadMarketUpdateDocs,
  saveDocSize,
} from '../../actions';
import { PDFmodal } from './PDFmodal';

const PDFViewer = ({
  documentFile,
  openDocument,
  content,
  page,
  setPage: setPageOriginal,
  onPageChanged,
  setNumPages,
  activePage,
  isThumbnails,
  isFullScreen,
  setThubsOverflow,
  zoomValue,
  saveDocSize,
}) => {
  const canvasRef = useRef(null);
  const [docFullscreen, setDocFullScreen] = useState();
  const setPage = (page) => {
    setPageOriginal(page);
    if (onPageChanged) {
      onPageChanged(page);
    }
  };

  const getUpdatedScale = () => {
    if (!isThumbnails && isFullScreen) {
      setTimeout(() => {
        saveDocSize(canvasRef.current.clientHeight);
      });
    }
    return isFullScreen ? zoomValue : 0.8;
  };

  const { pdfDocument } = usePdf({
    file: content,
    page,
    canvasRef,
    scale: getUpdatedScale(),
    onDocumentLoadSuccess(a) {
      if (!isThumbnails) {
        setNumPages(a.numPages);
        saveDocSize(canvasRef.current.clientHeight);
      }
    },
  });

  const onPreviousPage = () => {
    setThubsOverflow(true);
    setPage(page - 1);
  };

  const onNextPage = () => {
    setThubsOverflow(true);
    setPage(page + 1);
  };

  const onDownloadDocument = () => {
    openDocument(documentFile);
  };

  const onDocumentClick = () => {
    if (isThumbnails) {
      setPage(page);
    }
  };

  const onFullScrean = () => {
    setDocFullScreen(documentFile);
  };

  const onCloseFullScreen = (page) => {
    setDocFullScreen(null);
    setPage(page);
  };

  const createHelperButtons = () => {
    if (!isFullScreen) {
      return (
        <div className="helper-buttons">
          <Button type="button" className="expandPDF" onClick={onFullScrean}>
            <i className="fas fa-expand-alt" />
          </Button>
          <Button
            color="secondary"
            className="text-nowrap downloadPDF"
            onClick={onDownloadDocument}>
            Download PDF
          </Button>
        </div>
      );
    }
  };

  const calculateCanvasClass = () => {
    if (isFullScreen && !isThumbnails) {
      return 'pdf-canvas-full-screen';
    } else if (isThumbnails) {
      return activePage === page
        ? 'pdf-thumbnail-canvas pdf-thumbnail-active-canvas'
        : 'pdf-thumbnail-canvas';
    }
    return 'pdf-canvas';
  };

  const calculateCanvasPanel = () => {
    if (isFullScreen && !isThumbnails) {
      return 'canvas-panel order-sm-0 order-md-0 order-lg-1';
    } else if (isThumbnails) {
      return '';
    }
    return 'canvas-panel order-sm-0 order-md-0 order-lg-1';
  };

  const calculateLeftButtonPosition = () => {
    if (isFullScreen) {
      if (zoomValue < 1) {
        return 'btn-round-xl btn-margin-left col-sm-4 col-md-4 order-sm-3 order-md-3 order-lg-1 order-xl-1';
      }
      return 'btn-round-xl btn-margin-left col-sm-4 col-md-4 order-sm-3 order-md-3 order-lg-2 order-xl-1';
    }
    return 'btn-round-xl btn-margin-left col-sm-4 col-md-4 order-sm-2 order-md-2 order-lg-2 order-xl-1';
  };

  const calculateRightButtonPosition = () => {
    if (isFullScreen && zoomValue < 1) {
      return 'btn-round-xl btn-margin-right col-sm-4 order-sm-4 order-md-4 order-lg-2';
    }
    return 'btn-round-xl btn-margin-right col-sm-4 order-sm-4 order-md-4 order-lg-2';
  };

  return (
    <div onClick={onDocumentClick}>
      {Boolean(!isThumbnails && pdfDocument && pdfDocument.numPages) &&
        createHelperButtons()}

      {Boolean(!isThumbnails) && !pdfDocument && <span>Loading...</span>}
      <div className="pdf-main-container col-lg-12 flex-wrap">
        {Boolean(!isThumbnails && pdfDocument && pdfDocument.numPages) && (
          <button
            type="button"
            className={calculateLeftButtonPosition()}
            disabled={page === 1}
            onClick={onPreviousPage}>
            <i className="fas fa-angle-left" />
          </button>
        )}

        <div className={calculateCanvasPanel()}>
          <canvas className={calculateCanvasClass()} ref={canvasRef} />
        </div>

        {Boolean(!isThumbnails && pdfDocument && pdfDocument.numPages) && (
          <button
            type="button"
            className={calculateRightButtonPosition()}
            disabled={page === pdfDocument.numPages}
            onClick={onNextPage}>
            <i className="fas fa-angle-right" />
          </button>
        )}
      </div>
      {Boolean(pdfDocument) && (
        <PDFmodal
          doc={docFullscreen}
          onCloseFullScreen={onCloseFullScreen}
          page={page}
        />
      )}
    </div>
  );
};

PDFViewer.propTypes = {
  documentFile: PropTypes.object,
  openDocument: PropTypes.func,
  content: PropTypes.string,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setNumPages: PropTypes.func.isRequired,
  activePage: PropTypes.number,
  isThumbnails: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  marketUpdateDocsViewed: PropTypes.array,
  setThubsOverflow: PropTypes.func,
  setZoom: PropTypes.number,
  setFullZoom: PropTypes.number,
  zoomValue: PropTypes.number,
  onPageChanged: PropTypes.func,
  saveDocSize: PropTypes.func,
  pdfCanvasSize: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    marketUpdateDocsViewed: state.resources.market_update_docs.data || [],
    pdfCanvasSize: state.ui.pdfCanvasSize,
  };
};

export default connect(mapStateToProps, {
  openMarketUpdates,
  openDocument,
  loadMarketUpdateDocs,
  saveDocSize,
})((props) => {
  const [fileName, setFileName] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [page, setPage] = useState(props.page);
  const [numPages, setNumPages] = useState();
  const [thumbsInc, setThumbsInc] = useState(0);
  const [thubsOverflow, setThubsOverflow] = useState(false);

  const canvas = props.pdfCanvasSize;
  const doc = props.documentFile;
  const isFullScreen = props.isFullScreen;

  const onNextThumbs = () => {
    setThumbsInc(thumbsInc + 1);
  };

  const onPrevThumbs = () => {
    setThumbsInc(thumbsInc - 1);
  };

  const showThumbs = () => {
    const THUMBS_HEIGHT = 90;
    const BORDERS_SIZE = 65;
    const isIpad = window.innerHeight < window.innerWidth;
    const thumbsOverflowModal = isIpad
      ? THUMBS_HEIGHT + BORDERS_SIZE + canvas > window.innerHeight
      : THUMBS_HEIGHT + BORDERS_SIZE + canvas > window.innerWidth;
    return !thumbsOverflowModal || !isFullScreen ? true : false;
  };

  const createThumbnails = (blobProps) => {
    const thumbs = [];
    const maxThumbs = Math.min(numPages, 5);
    const lastPage = thumbsInc + maxThumbs;

    if (numPages) {
      if (thubsOverflow) {
        if (page > lastPage) {
          onNextThumbs();
        } else if (page <= thumbsInc) {
          onPrevThumbs();
        }
        setThubsOverflow(false);
      }
      for (let i = 1; i <= maxThumbs; i++) {
        thumbs.push(
          <PDFViewer
            key={i}
            {...blobProps}
            page={i + thumbsInc}
            activePage={page}
            zoomValue={0.15}
          />
        );
      }
      if (showThumbs()) {
        if (numPages > maxThumbs) {
          return (
            <div
              className={'pdf-thumbnails d-flex justify-content-center pt-4'}>
              <button
                type="button"
                className="btn-round"
                disabled={thumbsInc === 0}
                onClick={onPrevThumbs}>
                <i className="fas fa-angle-left fa-2x" />
              </button>
              {thumbs}
              <button
                type="button"
                className="btn-round"
                disabled={thumbsInc + maxThumbs >= numPages}
                onClick={onNextThumbs}>
                <i className="fas fa-angle-right fa-2x" />
              </button>
            </div>
          );
        }
        return <div className="pdf-thumbnails">{thumbs}</div>;
      }
    }
    return null;
  };

  const checkAlreadyViewed = (doc) => {
    return props.marketUpdateDocsViewed.find(
      (dv) => parseInt(dv.eventData, 10) === doc.objectDocumentId
    );
  };

  if (fileName !== doc.fileName) {
    const mustNotifyRead = !checkAlreadyViewed(doc);
    setTimeout(() => {
      if (!isFullScreen) {
        setPage(1);
      }
      setThumbsInc(0);
      setFileName(doc.fileName);
      props
        .openMarketUpdates(doc, mustNotifyRead)
        .then((c) => {
          setBlobUrl(c);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error('Error loading the PDF ' + error));
    });
  }
  if (blobUrl) {
    const blobProps = {
      isFullScreen: false,
      ...props,
      content: blobUrl,
      isThumbnails: true,
      isSelectedThumbnails: false,
      setPage,
      setNumPages,
      setThubsOverflow,
    };
    return (
      <div>
        <PDFViewer {...blobProps} isThumbnails={false} page={page} />
        {createThumbnails(blobProps)}
      </div>
    );
  } else {
    return null;
  }
});
