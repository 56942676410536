export const GET_OKTA_SETTINGS_SUCCESS = 'GET_OKTA_SETTINGS_SUCCESS';
export const GET_OKTA_SETTINGS_FAILED = 'GET_OKTA_SETTINGS_FAILED';
export const SAVE_OKTA_USER_AND_TOKEN = 'SAVE_OKTA_USER_AND_TOKEN';

export const OPEN_MANAGER = 'OPEN_MANAGER';
export const OPEN_MANAGER_SUCCESS = 'OPEN_MANAGER_SUCCESS';
export const OPEN_MANAGER_FAILURE = 'OPEN_MANAGER_FAILURE';

export const SHOW_DD_EVENT = 'SHOW_DD_EVENT';

export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILED = 'SAVE_PROFILE_FAILED';

export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILED = 'ACTIVATE_USER_FAILED';

export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILED = 'DEACTIVATE_USER_FAILED';

export const USER_ACTIVATIONS_STARTED = 'USER_ACTIVATIONS_STARTED';
export const USER_ACTIVATIONS_SUCCESS = 'USER_ACTIVATIONS_SUCCESS';
export const USER_ACTIVATIONS_FAILED = 'USER_ACTIVATIONS_FAILED ';

export const DISPLAY_MEETING_NOTES_SUCCESS = 'DISPLAY_MEETING_NOTES_SUCCESS';
export const DISPLAY_MEETING_NOTES_FAILED = 'DISPLAY_MEETING_NOTES_FAILED';

export const TOGGLE_WHAT_IS = 'TOGGLE_WHAT_IS';
export const SELECT_PLAN = 'SELECT_PLAN';
export const UI_OPEN_PLAN = 'UI_OPEN_PLAN';
export const REDIRECT = 'REDIRECT';
export const LOGOUT = 'LOGOUT';
export const LOADING = 'LOADING';
export const FILTER_DOCUMENT_BY_TYPE = 'FILTER_DOCUMENT_BY_TYPE';
export const FILTER_DOCUMENT_ON_GRID = 'FILTER_DOCUMENT_ON_GRID';
export const TOGGLE_FEEDBACK_MODAL = 'TOGGLE_FEEDBACK_MODAL';
export const CLEAR_FEEDBACK_MODAL = 'CLEAR_FEEDBACK_MODAL';

export const UI_FEEDBACK = 'UI_FEEDBACK';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL';

export const SORT_CHANGE = 'SORT_CHANGE';

export const PDF_CANVAS_SIZE = 'PDF_CANVAS_SIZE';

export const INVESTMENT_FIRM = 'INVESTMENT_FIRM';
export const EMPLOYEE_ACTIVE_TAB = 'EMPLOYEE_ACTIVE_TAB';
export const EMPLOYEE_MANAGER_MEETING_NOTES_SWITCH =
  'EMPLOYEE_MANAGER_MEETING_NOTES_SWITCH';
export const EMPLOYEE_DD_EVENTS_SWITCH = 'EMPLOYEE_DD_EVENTS_SWITCH';

export const HIDE_UNPUBLISHED_PLANS = 'HIDE_UNPUBLISHED_PLANS';

export const PRINTING = 'PRINTING';
