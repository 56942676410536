import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import BaseHeader from './BaseHeader';

export class ManagerSummaryHeader extends React.Component {
  renderWhatIsLinks(strategy) {
    const isOwnedLed =
      strategy.diverseOwnedLed === 'Led' || strategy.diverseOwnedLed === 'Owned'
        ? strategy.diverseOwnedLed
        : '---';

    const drawWhatIs = (field, action) => {
      const value =
        field === 'diverseOwnedLed'
          ? isOwnedLed
          : _.get(strategy, field, '') || '';
      return (
        <span>
          {value}
          <Button color="link" className="whatIsLink" onClick={action}>
            <i className="fa fa-info-circle"></i>
          </Button>
        </span>
      );
    };
    const diverseOwnedLed = drawWhatIs(
      'diverseOwnedLed',
      this.props.showWhatIsOwnedLed
    );

    return {
      diverseOwnedLed,
    };
  }

  render() {
    const headers = [
      { title: 'Website', field: 'website' },
      // { title: 'NEPC Client Exposure to Firm', field: 'sumOfClientExposureIv' },
      {
        title: 'Firm Due Diligence Status',
        field: 'duediligenceStatus',
      },
      { title: 'Diverse Owned/Led', field: 'diverseOwnedLed' },
    ];
    const { managerSummary } = this.props;
    const title = managerSummary.firmName;
    let websiteUrl = managerSummary.website;
    if (
      websiteUrl &&
      !(websiteUrl.indexOf('http://') > -1) &&
      !(websiteUrl.indexOf('https://') > -1)
    ) {
      websiteUrl = 'http://' + websiteUrl;
    }
    const { diverseOwnedLed } = this.renderWhatIsLinks(managerSummary);
    const data = {
      ...managerSummary,
      diverseOwnedLed,
      // sumOfClientExposureIv: formatCurrency(
      //   managerSummary.sumOfClientExposureIv
      // ),
      website: (
        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
          {websiteUrl}
        </a>
      ),
    };

    return (
      <BaseHeader
        headers={headers}
        data={data}
        title={title}
        isSingleColumn={true}
      />
    );
  }
}

ManagerSummaryHeader.propTypes = {
  managerSummary: PropTypes.object.isRequired,
  showWhatIsOwnedLed: PropTypes.func.isRequired,
};
export default ManagerSummaryHeader;
