import axios from 'axios';

import Constants from './Constants';

const HubSpotService = () => {
  const HUBSPOT_URL = Constants.HUBSPOT.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getVersion = () =>
    axios.get(`${HUBSPOT_URL}/maintenance/clienthubVersion?${Math.random()}`);

  const sendNotificationTest = (accessToken, notification) =>
    axios.post(
      `${HUBSPOT_URL}/notification/test`,
      { ...notification },
      _headers(accessToken)
    );

  const sendNotificationEmployees = accessToken =>
    axios.post(
      `${HUBSPOT_URL}/notification/runBatch/employees`,
      {},
      _headers(accessToken)
    );

  const sendNotificationClients = accessToken =>
    axios.post(
      `${HUBSPOT_URL}/notification/runBatch/clients`,
      {},
      _headers(accessToken)
    );

  return {
    getVersion,
    sendNotificationTest,
    sendNotificationEmployees,
    sendNotificationClients,
  };
};

export default HubSpotService;
