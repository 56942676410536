import React, { Component } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Plans from './plans/Plans';
import ConsultingResources from './consulting_resources/ConsultingResources';
import Documents from './plans/documents/Documents';
import Profile from './Profile';
import HubAdmin from './messages/HubAdmin';
import TermsAndConditions from './terms/TermsAndConditions';
import Skyan from './Skyan/Skyan';
import Constants from '../services/Constants';
import viewParams from './router/ViewParams';
import MessagesModal from './plans/documents/MessagesModal';
import Videos from './Videos/Videos';

export class Content extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedView !== nextProps.selectedView) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { selectedView, viewParams } = this.props;
    const contents = {
      [Constants.PLANS_VIEW]: Plans,
      [Constants.CONSULTING_RESOURCES_VIEW]: ConsultingResources,
      [Constants.DOCUMENTS_VIEW]: Documents,
      [Constants.PROFILE_VIEW]: Profile,
      [Constants.HUB_SETTINGS_VIEW]: HubAdmin,
      [Constants.TERMS_AND_CONDITIONS_VIEW]: TermsAndConditions,
      [Constants.SKYAN]: Skyan,
      [Constants.VIDEOS]: Videos,
    };
    const Comp = contents[selectedView];
    if (Comp) {
      return (
        <div className="content">
          <TabContent>
            <TabPane>
              <Comp viewParams={viewParams} />
            </TabPane>
          </TabContent>
          <MessagesModal />
        </div>
      );
    }
    return null;
  }
}

Content.propTypes = {
  selectedView: PropTypes.string.isRequired,
  match: PropTypes.object,
  viewParams,
};

export default withRouter(Content);
