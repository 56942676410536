import axios from 'axios';
import download from 'downloadjs';
import _ from 'lodash';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';

import Constants from './Constants';

const DocumentService = () => {
  const MFILES_URL = Constants.MFILES.URL;

  const _headers = accessToken => ({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getClientDeliverables = (accessToken, planId) => {
    return axios.get(
      `${MFILES_URL}/documentsListView?documentRequested=${Constants.CLIENT_DELIVERABLES}&planId=${planId}`,
      _headers(accessToken)
    );
  };

  const getPrivateMarket = (accessToken, planShortCode) => {
    return axios.get(
      `${MFILES_URL}/privateMarketListView?planShortCode=${planShortCode}`,
      _headers(accessToken)
    );
  };

  const getResearchDocuments = (accessToken, planType) => {
    return axios.get(
      `${MFILES_URL}/documentsListView?documentRequested=${
        Constants.RESEARCH_DOCUMENTS
      }&planTypeName=${planType}&fromDate=${moment()
        .add(-3, 'years')
        .calendar()}`,
      _headers(accessToken)
    );
  };

  const checkDocumentsByPlan = (
    accessToken,
    planIds,
    planTypes,
    email,
    fileId
  ) => {
    return axios.post(
      `${MFILES_URL}/checkDocument?email=${email}&fileId=${fileId}`,
      {
        planTypes: [...planTypes],
        planIds: [...planIds],
      },
      _headers(accessToken)
    );
  };

  const retrieveDocument = (accessToken, documentFile) => {
    let url = `${MFILES_URL}/documentView?`;
    url += `documentTitle=${encodeURIComponent(documentFile.fileName)}&`;
    url += `objType=${documentFile.objectType}&`;
    url += `objId=${documentFile.objectDocumentId}&`;
    url += `objVer=${documentFile.objectVersion}&`;
    url += `fileId=${documentFile.filedocumentId}`;

    const authenticationHeaders = _headers(accessToken);
    const responseType = { responseType: 'arraybuffer' };
    const options = { ...responseType, ...authenticationHeaders };
    return axios.get(url, options);
  };

  const createBlob = (contents, mimeType = 'application/pdf') => {
    const blob = new Blob([contents], {
      type: mimeType,
    });
    const binaryMimeTypes = ['application/octet-stream', 'text/plain'];
    const isBinary = _.includes(binaryMimeTypes, mimeType);
    return !isBinary && URL.createObjectURL(blob);
  };

  const openDocumentFile = (
    fileName,
    contents,
    mimeType = 'application/pdf'
  ) => {
    const url = createBlob(contents, mimeType);
    const isIE =
      (window.navigator && !!window.navigator.msSaveOrOpenBlob) || false;
    const wind = url && !isIE && window.open(url);
    if (!wind) {
      download(contents, fileName);
    }
  };

  const downloadDocumentDirect = (accessToken, documentFile) => {
    return retrieveDocument(accessToken, documentFile).then(
      ({ data: file }) => {
        download(file, documentFile.fileName);
      }
    );
  };

  const downloadDocument = (accessToken, documentFile) => {
    return retrieveDocument(accessToken, documentFile).then(
      ({ data: file, headers }) => {
        const mimeType = headers['content-type'];
        openDocumentFile(documentFile.fileName, file, mimeType);
      }
    );
  };

  const fetchBlobDocument = (accessToken, documentFile) => {
    return retrieveDocument(accessToken, documentFile).then(
      ({ data: file, headers }) => {
        const mimeType = headers['content-type'];
        return new Promise(resolve => {
          resolve(createBlob(file, mimeType));
        });
      }
    );
  };

  const documentFromMFiles = mFileDoc => {
    if (mFileDoc) {
      const splitTypes = /\s*;\s*/;
      const docs = {
        objectDocumentId: _.get(mFileDoc, 'objVer.ID'),
        objectVersion: _.get(mFileDoc, 'objVer.Version'),
        objectType: _.get(mFileDoc, 'objVer.Type'),
        filedocumentId: _.get(mFileDoc, 'fileId'),
        fileName: _.get(mFileDoc, 'fileName'),
        title: _.get(mFileDoc, 'title'),
        publishedBy: _.get(mFileDoc, 'publishedBy'),
        publishedDate: _.get(mFileDoc, 'publishedDate'),
        publishingLocation: _.get(mFileDoc, 'publishingLocation'),
        expirationDate: _.get(mFileDoc, 'expirationDate'),
        deliverableType: (_.get(mFileDoc, 'deliverableType') || '').split(
          splitTypes
        ),
        asOfDate: _.get(mFileDoc, 'asOfDate'),
      };
      NotificationManager.success('Starting download', '', 4000);
      return docs;
    }
    NotificationManager.error(
      'Document not Available to your plans.',
      '',
      4000
    );
  };

  const documentListFromMFiles = MFilesDocuments => {
    const splitTypes = /\s*;\s*/;
    const docs = _.map(MFilesDocuments, mFileDoc => {
      return {
        objectDocumentId: _.get(mFileDoc, 'objVer.ID'),
        objectVersion: _.get(mFileDoc, 'objVer.Version'),
        objectType: _.get(mFileDoc, 'objVer.Type'),
        filedocumentId: _.get(mFileDoc, 'fileId'),
        fileName: _.get(mFileDoc, 'fileName'),
        title: _.get(mFileDoc, 'title'),
        publishedBy: _.get(mFileDoc, 'publishedBy'),
        publishedDate: _.get(mFileDoc, 'publishedDate'),
        publishingLocation: _.get(mFileDoc, 'publishingLocation'),
        expirationDate: _.get(mFileDoc, 'expirationDate'),
        deliverableType: (_.get(mFileDoc, 'deliverableType') || '').split(
          splitTypes
        ),
        asOfDate: _.get(mFileDoc, 'asOfDate'),
      };
    });
    return _.chain(docs)
      .sortBy(d => moment.utc(d.publishedDate).valueOf())
      .reverse()
      .value();
  };

  return {
    getClientDeliverables,
    getResearchDocuments,
    downloadDocumentDirect,
    downloadDocument,
    fetchBlobDocument,
    openDocumentFile,
    documentListFromMFiles,
    documentFromMFiles,
    checkDocumentsByPlan,
    getPrivateMarket,
  };
};

export default DocumentService;
