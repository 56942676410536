import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { InputGroup, Input, Button, Col, Row } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

import DocumentsGrid from '../plans/documents/DocumentsGrid';
import OpenDDEventLink from '../plans/documents/OpenDDEventLink';
import MeetingNotesModal from '../plans/documents/MeetingNotesModal';
import InvestmentFirmLink from '../plans/documents/InvestmentFirmLink';
import { dateColumn } from '../../services/DateService';
import { textColumn, equalsIgnoreCase } from '../../services/StringService';
import {
  loadEmployeeDDEventsMyManager,
  loadEmployeeDDEventsAllManagers,
  printPortfolio,
  openDDEvent,
} from '../../actions';
import renderTableGrid from '../plans/documents/TablePrintHtml';
import Constants, { Events } from '../../services/Constants';
import InvestmentFirmPanel from './InvestmentFirmPanel';
import { slug } from '../../services/SlugService';
import WhatIsModal from '../plans/documents/WhatIsModal';

export class EmployeeDDEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerUrl: props.managerUrl,
      filteredIDs: [],
      hasFilter: false,
      filter: {},
      openModal: true,
      warned: false,
    };
  }

  static columnDefs = [
    {
      field: 'investmentFirmName',
      headerName: 'Investment Firm',
      cellRenderer: InvestmentFirmLink,
      minWidth: 100,
      ...textColumn,
    },
    {
      field: 'eventTitle',
      headerName: 'Event Title',
      cellRenderer: OpenDDEventLink,
      width: 490,
      suppressSizeToFit: true,
      ...textColumn,
    },
    {
      field: 'ddSubcategory',
      headerName: 'Subcategory',
      width: 109,
      minWidth: 109,
      flex: 1,
      ...textColumn,
    },
    {
      field: 'eventDate',
      headerName: 'Date',
      sort: 'desc',
      width: 120,
      ...dateColumn,
    },
    {
      field: 'firmDueDiligenceStatus',
      headerName: 'Status Change',
      width: 150,
      suppressSizeToFit: true,
      ...textColumn,
    },
    {
      field: 'levelOfImpact',
      headerName: 'Level of Impact',
      width: 70,
      ...textColumn,
    },
  ];

  getEmployeeDDEvents = () => {
    const {
      allDDEvents,
      employeeDDEventsMyManager,
      employeeDDEventsAllManagers,
    } = this.props;
    return allDDEvents
      ? employeeDDEventsAllManagers
      : employeeDDEventsMyManager;
  };

  openDDEventsModal = () => {
    const { data } = this.getEmployeeDDEvents();
    let ddEventsNotFound = true;

    if (data?.length > 0) {
      if (this.state.openModal && this.props.ddEventId) {
        data.forEach((ddEvent) => {
          const slugedEventId = slug(ddEvent.eventId);
          if (equalsIgnoreCase(slugedEventId, this.props.ddEventId)) {
            this.setState({
              openModal: false,
            });
            ddEventsNotFound = false;
            return this.props.openDDEvent(ddEvent);
          }
        });
        if (ddEventsNotFound && !this.state.warned) {
          this.setState({ warned: true });
          NotificationManager.warning(
            'DD Events not found for this user',
            'DD Events'
          );
        }
      }
    }
    return null;
  };

  renderManagerTab = () => {
    const { documentView, investmentFirm, managerUrl, strategyName } =
      this.props;

    if (this.getEmployeeDDEvents()?.data) {
      const ddEvents = this.getEmployeeDDEvents()?.data.find(
        (e) => slug(e.investmentFirmName) === investmentFirm
      );
      if (ddEvents) {
        return (
          <InvestmentFirmPanel
            firmId={ddEvents.investmentFirmId}
            documentView={documentView}
            managerUrl={managerUrl}
            investmentFirm={investmentFirm}
            strategyName={strategyName}
            baseUrl="/consultingResources/dds/"
          />
        );
      }
      return null;
    }
    return null;
  };

  print = () => {
    const { viewParams, user, printPortfolio } = this.props;
    const { filteredIDs } = this.state;
    const event = Events.PRINT_EMPLOYEE_PORTFOLIO_DDS;
    const filteredEmployeeDDEvents =
      filteredIDs.length > 0
        ? this.getEmployeeDDEvents().data.filter(
            (item) => filteredIDs.indexOf(item.eventId) >= 0
          )
        : this.getEmployeeDDEvents().data;
    const grid = renderTableGrid(
      EmployeeDDEvents.columnDefs,
      viewParams,
      filteredEmployeeDDEvents,
      Constants.ROWS_PER_PAGE_DD_EVENTS,
      'Employee Due Diligence Events'
    );

    const planData = {
      planName: 'Employee Due Diligence Events',
      user: user.email,
    };

    const html = `
      <h1>Employee Due Diligence Events</h1>
      ${grid}`;

    printPortfolio(event, planData, viewParams.selectedTab, html);
  };

  onCustomFilterRow = (data) => {
    const filteredIDs = [];
    data.api.forEachNodeAfterFilter((node, index) => {
      filteredIDs.push(node.data['eventId']);
    });
    if (this.getEmployeeDDEvents().data) {
      const hasFilter =
        filteredIDs.length < this.getEmployeeDDEvents().data.length;
      return this.setState({ filteredIDs: filteredIDs, hasFilter: hasFilter });
    }
    this.setState({ filteredIDs: filteredIDs, hasFilter: false });
  };

  ddEvents = () => {
    const { filter, hasFilter } = this.state;
    return (
      <div>
        <Row className="consultingResourcesInputSearchDDE justify-content-between">
          <Col md={5} lg={3}>
            <InputGroup>
              <Input
                type="search"
                className="consultingResourcesInputBar"
                id="inputBar-ddevents"
                placeholder="Firm search"
                value={filter.investmentFirmName}
                onChange={(event) => {
                  this.setState({
                    filter: {
                      investmentFirmName: event.target.value.toLowerCase(),
                    },
                  });
                }}
              />
            </InputGroup>
          </Col>
          <Col md={2} lg={2} className="text-end downloadCard">
            <Button
              id="downloadPdfButton"
              color="secondary"
              className="text-nowrap mx-auto downloadButtom"
              onClick={this.print}>
              Download PDF
            </Button>
            {Boolean(hasFilter) && (
              <span className="downloadText text-nowrap">
                Download will reflect any active filtering
              </span>
            )}
          </Col>
        </Row>
        <div className="messagesGrid ddgrid mt-3 mb-3 ag-theme-balham">
          <DocumentsGrid
            context={{}}
            columnDefs={EmployeeDDEvents.columnDefs}
            rowData={this.getEmployeeDDEvents().data || []}
            viewParams={{}}
            noRowsMessage="No Rows"
            hasLoaded={this.getEmployeeDDEvents()?.isLoaded}
            onCustomFilterRow={this.onCustomFilterRow}
            filterValue={filter}
            filterProperty={['investmentFirmName']}
          />
        </div>
        {this.openDDEventsModal()}
        <MeetingNotesModal showPlans={true} consultant={true} />
        <WhatIsModal />
      </div>
    );
  };

  componentDidMount() {
    const { email, allDDEvents } = this.props;
    if (allDDEvents) {
      this.props.loadEmployeeDDEventsAllManagers(email);
    } else {
      this.props.loadEmployeeDDEventsMyManager(email);
    }
  }

  render() {
    if (
      this.props.managerUrl &&
      this.props.activeTab === Constants.CR_DD_EVENTS_VIEW
    ) {
      return this.renderManagerTab();
    }
    if (this.props.allDDEvents !== this.state.allDDEvents) {
      this.componentDidMount();
      this.setState({
        allDDEvents: this.props.allDDEvents,
      });
    }
    return this.ddEvents();
  }
}

EmployeeDDEvents.propTypes = {
  loadEmployeeDDEventsMyManager: PropTypes.func.isRequired,
  loadEmployeeDDEventsAllManagers: PropTypes.func.isRequired,
  employeeDDEventsMyManager: PropTypes.object,
  employeeDDEventsAllManagers: PropTypes.object,
  investmentFirm: PropTypes.string,
  strategyName: PropTypes.string,
  ddEventId: PropTypes.string,
  openDDEvent: PropTypes.func,

  activeTab: PropTypes.string,
  printPortfolio: PropTypes.func,

  managerUrl: PropTypes.string,
  documentView: PropTypes.string,

  viewParams: PropTypes.object,
  user: PropTypes.object,
  isEmployee: PropTypes.bool,
  email: PropTypes.string,
  filterByDocumentType: PropTypes.object,
  filterDocumentOnGrid: PropTypes.object,
  allDDEvents: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.okta.user,
    email: _.get(state, 'okta.user.email'),
    isEmployee: state.okta.isEmployee,
    employeeDDEventsMyManager:
      state.resources.employee_dd_events_my_manager_list || {},
    employeeDDEventsAllManagers:
      state.resources.employee_dd_events_all_managers_list || {},
    activeTab: state.ui.employeeActiveTab,
    filterByDocumentType: state.ui.filterByDocumentType,
    filterDocumentOnGrid: state.ui.filterDocumentOnGrid,
  };
};

export default connect(mapStateToProps, {
  loadEmployeeDDEventsMyManager,
  loadEmployeeDDEventsAllManagers,
  printPortfolio,
  openDDEvent,
})(EmployeeDDEvents);
