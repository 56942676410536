import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Row,
  Input,
  Container,
  FormGroup,
  Label,
} from 'reactstrap';

import {
  changeDocumentsViewFilter,
  changeGridFilter,
  dispatchPrinting,
  loadAllStrategiesManagerList,
  loadFocusedPlacementList,
  loadFPLManagerActivities,
  openDDEvent,
  openMeetingNote,
  printPortfolio,
  redirectPlan,
  saveSortState,
  showWhatAreBetaGroups,
} from '../../../actions';
import {
  loadClientDeliverables,
  loadManagerActivities,
  loadManagerManagerActivities,
  loadManagerStrategies,
  loadMyManagers,
  loadNepcPublished,
  loadPortfolioDDs,
  loadPrivateMarket,
  loadResearchWriteUps,
  loadSearchManagers,
} from '../../../actions/Resources';
import { Constants, Events } from '../../../services/Constants';
import { formatDate } from '../../../services/DateService';
import { slug } from '../../../services/SlugService';
import viewParams from '../../router/ViewParams';
import SimpleTypeahead from '../../typeahead/SimpleTypeahead';
import {
  clientFileModeColumnDefs,
  focusedPlacementListColumnDefs,
  focusedPlacementListsColumnDefs,
  managerDDEventsColumnDefs,
  managerManagerActivitiesColumnDefs,
  managerNotesColumnDefs,
  managerStrategiesColumnDefs,
  meetingNotesColumnDefs,
  myManagersAssetColumnDefs,
  myManagersDDColumnDefs,
  privateMarketColumnDefs,
  researchFileModeColumnDefs,
  baseRatingColumnDefs,
  baseEndColumns,
  ddratingColumn,
} from './ColumnDefs';
import DocumentsFilter from './DocumentsFilter';
import DocumentsGrid from './DocumentsGrid';
import DocumentsGridTree from './DocumentsGridTree';
import renderTableGrid from './TablePrintHtml';

const ALL = 'All';

const nop = (o) => o;

const withParam = (loader, paramPath) => (viewParams) => {
  const paramValue = _.get(viewParams, paramPath);
  if (paramValue) {
    return loader(paramValue);
  }
  return null;
};
const withPlanId = (loader) => withParam(loader, 'plan.planId');
const withPlanIdGuid = (loader) => withParam(loader, 'plan.planIdGuid');
const withPlanType = (loader) => withParam(loader, 'plan.planType');
const withPlanShortCode = (loader) => withParam(loader, 'plan.planShortCode');
const withFplList = (loader) => withParam(loader, 'fplList');
const withFplStrategyId = (loader) =>
  withParam(loader, 'fplStrategy.strategyId');
const withManagerId = (loader) => withParam(loader, 'managerSummary.firmId');

const diverseFilters = ['esgRating', 'deiRating', 'diverseOwnedLed'];

const gridOptions = {
  [Constants.PLAN_DOCUMENTS_VIEW]: {
    columnDefs: clientFileModeColumnDefs,
    filterProperty: ['deliverableType'],
    showDocumentType: true,
    rowHeight: 30,
    data: 'client_deliverables',
    mapper: nop,
    loader: withPlanIdGuid(loadClientDeliverables),
    onErrorMessage: Constants.MFILES_DOWN_MESSAGE,
  },
  [Constants.PLAN_PRIVATE_MARKET_VIEW]: {
    columnDefs: privateMarketColumnDefs,
    filterProperty: ['investmentFirmFilter', 'managerDocumentType'],
    showDocumentType: true,
    rowHeight: 30,
    data: 'private_market',
    mapper: (data, viewParams) => {
      if (data) {
        return data.map((strategy) => {
          const newManagerDocumentType =
            strategy.managerDocumentType.split('; ');
          return {
            ...strategy,
            managerDocumentType: newManagerDocumentType,
          };
        });
      }
      return;
    },
    loader: withPlanShortCode(loadPrivateMarket),
    onErrorMessage: Constants.MFILES_DOWN_MESSAGE,
  },
  [Constants.PLAN_RESEARCH_DOCUMENTS_VIEW]: {
    columnDefs: researchFileModeColumnDefs,
    filterProperty: ['deliverableType'],
    showDocumentType: true,
    data: 'nepc_published',
    mapper: nop,
    loader: withPlanType(loadNepcPublished),
    onErrorMessage: Constants.MFILES_DOWN_MESSAGE,
  },
  [Constants.PLAN_MANAGER_MEETING_NOTES_VIEW]: {
    columnDefs: meetingNotesColumnDefs,
    filterProperty: [],
    showDocumentType: true,
    data: 'research_writeups',
    mapper: nop,
    loader: withPlanId(loadResearchWriteUps),
  },
  [Constants.PLAN_MANAGER_NOTES_VIEW]: {
    columnDefs: managerNotesColumnDefs,
    filterProperty: [],
    showDocumentType: true,
    data: 'plan_manager_activities',
    mapper: nop,
    loader: withPlanId(loadManagerActivities),
  },
  [Constants.PLAN_FOCUSED_PLACEMENT_LISTS]: {
    columnDefs: focusedPlacementListsColumnDefs,
    filterProperty: [
      'betaGroup',
      'assetClass',
      'fplListName',
      'dueDiligenceRating',
      'esgRating',
      'deiRating',
      'diverseOwnedLed',
    ],
    showDocumentType: true,
    data: 'all_managers',
    mapper: (data, viewParams) => {
      const filteredData = _.filter(data, (strategy) => {
        const { dueDiligenceRating } = strategy;
        return (
          dueDiligenceRating &&
          (dueDiligenceRating === '1' ||
            dueDiligenceRating === '2' ||
            dueDiligenceRating === '3')
        );
      });
      return filteredData.map((strategy) => {
        const newDiverseOwnedLed =
          strategy.diverseOwnedLed === 'Led' ||
          strategy.diverseOwnedLed === 'Owned'
            ? strategy.diverseOwnedLed
            : '';
        return { ...strategy, diverseOwnedLed: newDiverseOwnedLed };
      });
    },
    loader: (viewParams) => {
      return (dispatch) => {
        dispatch(withPlanId(loadSearchManagers)(viewParams));
        return dispatch(loadAllStrategiesManagerList());
      };
    },
  },
  [Constants.PLAN_FOCUSED_PLACEMENT_LIST]: {
    columnDefs: focusedPlacementListColumnDefs,
    filterProperty: ['dueDiligenceRating', 'esgRating', 'diverseOwnedLed'],
    showDocumentType: false,
    data: 'focused_placement_list',
    mapper: (data, viewParams) => {
      const filteredData = _.filter(data, (strategy) => {
        const { dueDiligenceRating } = strategy;
        return (
          dueDiligenceRating &&
          (dueDiligenceRating === '1' ||
            dueDiligenceRating === '2' ||
            dueDiligenceRating === '3')
        );
      });
      return filteredData.map((strategy) => {
        const newDiverseOwnedLed =
          strategy.diverseOwnedLed === 'Led' ||
          strategy.diverseOwnedLed === 'Owned'
            ? strategy.diverseOwnedLed
            : '';
        return { ...strategy, diverseOwnedLed: newDiverseOwnedLed };
      });
    },
    loader: withFplList(loadFocusedPlacementList),
  },
  [Constants.FPL_MANAGER_ACTIVITIES]: {
    columnDefs: managerManagerActivitiesColumnDefs,
    filterProperty: [],
    showDocumentType: false,
    data: 'fpl_manager_activities',
    mapper: nop,
    loader: withFplStrategyId(loadFPLManagerActivities),
  },
  [Constants.PLAN_PORTFOLIO]: {
    columnDefs: [
      ...myManagersAssetColumnDefs,
      ...baseRatingColumnDefs,
      ...baseEndColumns,
    ],
    filterProperty: ['ddRating'],
    showDocumentType: true,
    data: 'my_managers',
    groupDefaultExpanded: 1,
    gridState: {},
    mapper: (data, viewParams) => {
      if (!data) return [];
      return data.map((row) => {
        const newDiverseOwnedLed =
          row.diverseOwnedLed === 'Led' || row.diverseOwnedLed === 'Owned'
            ? row.diverseOwnedLed
            : '';

        const newDDRating = ['1', '2', '3'].includes(row.ddRating)
          ? row.ddRating
          : '';

        return {
          ...row,
          diverseOwnedLed: newDiverseOwnedLed,
          ddRating: newDDRating,
          assetClass:
            viewParams.plan && viewParams.plan.useClientAssetClasses
              ? row.clientAssetClass
              : row.assetClass,
          assetDate: formatDate(row.assetDate),
        };
      });
    },
    loader: withPlanId(loadMyManagers),
    dataKey: 'strategyId',
    treeTable: true,
  },
  [Constants.PLAN_PORTFOLIO_DD]: {
    columnDefs: myManagersDDColumnDefs,
    showDocumentType: true,
    data: 'plan_dd_events',
    mapper: nop,
    loader: withPlanId(loadPortfolioDDs),
    dataKey: 'eventId',
  },
  [Constants.PLAN_MANAGER_DD_EVENTS]: {
    columnDefs: managerDDEventsColumnDefs,
    filterProperty: [],
    showDocumentType: false,
    data: 'manager_dd_events',
    isRequired: true,
    mapper: nop,
    loader: withManagerId(loadManagerManagerActivities),
  },
  [Constants.PLAN_MANAGER_STRATEGIES]: {
    columnDefs: managerStrategiesColumnDefs,
    filterProperty: [
      'betaGroup',
      'assetClass',
      'fplListName',
      'dueDiligenceRating',
      'esgRating',
      'deiRating',
      'diverseOwnedLed',
    ],
    showDocumentType: false,
    data: 'manager_strategies',
    isRequired: true,
    mapper: (data, viewParams) => {
      if (data) {
        return data.map((strategy) => {
          const newDueDiligenceRating =
            strategy.dueDiligenceRating === '1' ||
            strategy.dueDiligenceRating === '2' ||
            strategy.dueDiligenceRating === '3'
              ? strategy.dueDiligenceRating
              : '';
          const newDiverseOwnedLed =
            strategy.diverseOwnedLed === 'Led' ||
            strategy.diverseOwnedLed === 'Owned'
              ? strategy.diverseOwnedLed
              : '';
          return {
            ...strategy,
            diverseOwnedLed: newDiverseOwnedLed,
            dueDiligenceRating: newDueDiligenceRating,
          };
        });
      }
      return null;
    },
    loader: withManagerId(loadManagerStrategies),
  },
  [Constants.EMPLOYEE_MANAGER_ACTIVITIES]: {
    columnDefs: managerManagerActivitiesColumnDefs,
    filterProperty: [],
    showDocumentType: false,
    data: 'manager_activities',
    isRequired: true,
    mapper: nop,
    loader: withManagerId(loadManagerManagerActivities),
  },
};

export class DocumentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedManager: undefined,
      openModal: true,
      hideDiverseColumns: false,
      filter: {},
    };
    this.treeGridRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.getGridOptions().filterProperty && !this.state.filter) {
      let filtering = {};
      this.getGridOptions().filterProperty.map((option) => {
        filtering = {
          ...filtering,
          [option]: null,
        };
      });
      this.setState({
        filter: filtering,
      });
      this.props.changeDocumentsViewFilter(this.props.documentView, filtering);
    }
  }

  handleFilterSelect = (selection) => {
    const valueKey = Object.keys(selection)[0];
    const value = selection[valueKey] !== ALL ? selection[valueKey] : null;
    let newFiltering = {};
    switch (valueKey) {
      case 'betaGroup':
        newFiltering = {
          betaGroup: value,
          assetClass: ['All'],
          fplListName: ['All'],
        };
        break;
      case 'assetClass':
        newFiltering = {
          betaGroup: this.state.filter.betaGroup,
          assetClass: value,
          fplListName: ['All'],
        };
        break;
      case 'investmentFirmFilter':
        if (this.props.documentView === Constants.PLAN_PRIVATE_MARKET_VIEW) {
          newFiltering = {
            investmentFirmFilter: value,
            managerDocumentType: ['All'],
          };
          break;
        }
      default:
        newFiltering = {
          ...this.props.filterByDocumentType[this.props.documentView],
          [this.getBaseName(valueKey)]: value,
        };
    }

    this.setState({
      filter: newFiltering,
      lastFilterSelected: valueKey,
    });
    this.props.changeDocumentsViewFilter(this.props.documentView, newFiltering);

    this.expandIfFiltered(Object.values(newFiltering));
  };

  /**
   * Expand all grid rows if there is at least one filter applied
   *
   * @param {*} filterValues
   */
  expandIfFiltered = (filterValues) => {
    const filteredValues = filterValues.filter(
      (v) => !(v?.indexOf('All') >= 0)
    );

    if (filteredValues.length > 0) {
      setTimeout(() => {
        this.expandTable();
      }, 200);
    }
  };

  /**
   * @param {String} value
   * @return {String} The prefix, starting from 0 to "_" index.
   */
  getBaseName = (value) => {
    return value.split('_')[0];
  };

  getFilteringValue = () => {
    return this.props.filterByDocumentType[this.props.documentView] || null;
  };

  onChangeTypeaheadValue = (event) => {
    this.setState({
      ...this.state,
      selectedManager: event,
    });
    const slugManagerName = slug(event.label);
    this.props.redirectPlan(`/manager/${slugManagerName}/strategies`);
  };

  loadOptions = (query) => {
    return new Promise((resolve) => {
      if (!query || query.length < 2) {
        resolve([]);
      }
      const options = _.chain(this.props.managerSearchList)
        .filter((manager) =>
          (manager.value || '').toLowerCase().includes(query.toLowerCase())
        )
        .map((manager) => {
          return {
            value: manager.key,
            label: manager.value,
            id: `investment-manager-${manager.key}`,
          };
        })
        .value();
      resolve(options);
    });
  };

  renderResetButton() {
    if (this.props.documentView === Constants.PLAN_PORTFOLIO) {
      return (
        <>
          <div
            className="text-right collapse-table"
            onClick={this.collapseTable}>
            Collapse All
          </div>
          <div className="text-right expand-table" onClick={this.expandTable}>
            Expand All
          </div>
        </>
      );
    }
    return <></>;
  }

  collapseTable = () => {
    if (this.treeGridRef.current) {
      this.treeGridRef.current.collapseAll();
    }
  };

  expandTable = () => {
    if (this.treeGridRef.current) {
      this.treeGridRef.current.expandAll();
    }
  };

  renderExportToCsvButton() {
    if (this.props.documentView === Constants.PLAN_PORTFOLIO) {
      return (
        <Button
          id="downloadCSVButton"
          color="secondary"
          className="text-nowrap mx-auto downloadButtom"
          onClick={this.exportToCsv}>
          Export to CSV
        </Button>
      );
    }
    return <></>;
  }

  renderDownloadPDFButton() {
    const printPDF = () => {
      const {
        viewParams,
        documentView,
        user,
        myManagers,
        myMeetingNotes,
        planManagerActivities,
        printPortfolio,
      } = this.props;
      const filterValues = (data) => {
        const { filterDocumentOnGrid: grid } = this.props;
        const mustFilterData = grid && grid.key && grid.ids && grid.hasFilter;
        return mustFilterData
          ? data.filter((item) => grid.ids.indexOf(item[grid.key]) >= 0)
          : data;
      };

      const filterMeetingNotes = (data) => {
        const { filterByDocumentType: grid } = this.props;
        const mustFilterData = grid['Manager Meeting Notes'] ? true : false;
        return mustFilterData
          ? data.filter((item) => {
              return item.reasonForMeeting === grid['Manager Meeting Notes']
                ? item
                : null;
            })
          : data;
      };

      const chart = document.getElementById('landing-page-chart')
        ? `<div class="landingPageChart">${
            document.getElementById('landing-page-chart').innerHTML
          }
          ${
            document.getElementById('reactgooglegraphToPDF').firstElementChild
              .innerHTML
          }
            </div>`
        : '';
      this.props.dispatchPrinting(false);

      let grid = '';
      let event;
      if (documentView === Constants.PLAN_PORTFOLIO) {
        const chartHeight =
          document.getElementById('landing-page-chart').offsetHeight;
        let firstPageRows = Constants.ROWS_PER_PAGE_MY_MANAGERS.firstPage;
        if (chartHeight > 345) {
          firstPageRows =
            Constants.ROWS_PER_PAGE_MY_MANAGERS.firstPage -
            (chartHeight - 345) / 25;
        }

        const columnDef = this.state.hideDiverseColumns
          ? [...myManagersAssetColumnDefs, ddratingColumn, ...baseEndColumns]
          : [
              ...myManagersAssetColumnDefs,
              ...baseRatingColumnDefs,
              ...baseEndColumns,
            ];
        grid = renderTableGrid(
          columnDef,
          viewParams,
          filterValues(myManagers),
          {
            firstPage: firstPageRows,
            postPages: Constants.ROWS_PER_PAGE_MY_MANAGERS.postPages,
          },
          {
            sort: 'asc',
            colId: 'asset_type_sort_order',
          },
          documentView
        );
        event = Events.PRINT_PORTFOLIO_HOLDINGS;
      } else if (documentView === Constants.FPL_MANAGER_ACTIVITIES) {
        grid = renderTableGrid(
          managerNotesColumnDefs,
          viewParams,
          planManagerActivities,
          Constants.ROWS_PER_PAGE_DD_EVENTS,
          { colId: 'date', sort: 'desc' },
          documentView
        );
        event = Events.PRINT_PORTFOLIO_DDS;
      } else {
        grid = renderTableGrid(
          meetingNotesColumnDefs,
          viewParams,
          filterMeetingNotes(myMeetingNotes),
          Constants.ROWS_PER_PAGE_MEETING_NOTES,
          { colId: 'date', sort: 'desc' },
          'Employee Manager Meeting Notes'
        );
        event = Events.PRINT_MANAGER_MEETING_NOTES;
      }

      const planData = {
        planName: viewParams.plan.planName,
        user: user.email,
        documentType: 'Portfolio Summary',
      };

      const html = `
          <h2>Portfolio Summary</h2>
          ${chart}
          <div class="grid-view">
            ${grid}
          </div>
        `;
      printPortfolio(event, planData, viewParams.plan.planName, html);
    };
    if (this.props.documentView === Constants.PLAN_PORTFOLIO) {
      return (
        <Button
          id="downloadPdfButton"
          color="secondary"
          className="text-nowrap mx-auto downloadButtom"
          onClick={() => {
            this.props.dispatchPrinting(true);
            setTimeout(() => printPDF(), 200);
          }}>
          Download PDF
        </Button>
      );
    }
    return <></>;
  }

  exportToCsv = () => {
    this.treeGridRef.current.exportToCsv(
      this.props.viewParams.plan.planName + ' Portfolio.csv'
    );
  };

  renderDocumentType() {
    if (!this.getGridOptions().showDocumentType) {
      return null;
    }
    const selectedManager = this.state.selectedManager;
    const isFplList =
      this.props.documentView === Constants.PLAN_FOCUSED_PLACEMENT_LISTS;

    const CustomOption = ({ innerProps, data }) => (
      <div className="doctype-option" {...innerProps} id={data.id}>
        {data.label}
      </div>
    );
    const documentTitle = isFplList
      ? Constants.INVESTMENT_STRATEGY_GROUPS
      : this.props.documentView;

    return (
      <Row>
        <Col md={12} lg={8}>
          <h5 className="menuTitle">{documentTitle}</h5>
        </Col>
        {Boolean(isFplList) && (
          <Col md={6} lg={4}>
            <div
              className={isFplList ? 'managersSearch' : 'hideManagersSearch'}>
              <SimpleTypeahead
                className="managersSearch"
                id="manager-search-bar"
                loadOptions={this.loadOptions}
                onChange={this.onChangeTypeaheadValue}
                value={selectedManager}
                placeHolder="Search for Investment Managers"
                customComponents={{ Option: CustomOption }}
              />
            </div>
          </Col>
        )}
        {Boolean(!isFplList) && (
          <Col lg={4} className="buttons-container">
            {/* {this.renderWhatIsBetaGroup()} */}
            {this.renderExportToCsvButton()}
            {this.renderDownloadPDFButton()}
          </Col>
        )}
      </Row>
    );
  }

  getFilterProperty = (property) => {
    let name;
    if (property) {
      name = this.getGridOptions().filterProperty.find(
        (element) => element === property
      );
    } else {
      if (
        this.props.documentView === Constants.PLAN_PORTFOLIO &&
        !this.state.hideDiverseColumns
      ) {
        name = [...this.getGridOptions().filterProperty, ...diverseFilters];
      } else name = this.getGridOptions().filterProperty;
    }
    if (name && typeof name === 'function') {
      return name(this.props.viewParams);
    }
    return name;
  };

  renderFiltering = () => {
    if (!this.getFilterProperty()) {
      return null;
    }
    const filterProperty = this.getFilterProperty();

    const byFilterProp = (property, refresh) => {
      const { filterByDocumentType, documentView } = this.props;
      const sharedFilter = filterByDocumentType[documentView];
      if (
        refresh ||
        (documentView !== Constants.PLAN_FOCUSED_PLACEMENT_LISTS &&
          documentView !== Constants.PLAN_MANAGER_STRATEGIES)
      ) {
        let filteredRows = this.getRowData();
        if (sharedFilter) {
          filteredRows = filteredRows.filter((row) => {
            let shouldReturnRow = true;
            const properties = Object.keys(sharedFilter);
            properties.forEach((item) => {
              if (
                shouldReturnRow &&
                property !== item &&
                !(property === 'assetClass' && item === 'fplListName')
              ) {
                if (Array.isArray(row[item])) {
                  shouldReturnRow =
                    !sharedFilter[item] ||
                    sharedFilter[item].includes('All') ||
                    row[item].some((filter) =>
                      sharedFilter[item].includes(filter)
                    );
                } else if (item === 'investmentFirmName' || item === 'firm') {
                  shouldReturnRow =
                    !sharedFilter[item] ||
                    row[item].toLowerCase().includes(sharedFilter[item]);
                } else {
                  shouldReturnRow =
                    !sharedFilter[item] ||
                    sharedFilter[item].includes('All') ||
                    sharedFilter[item].includes(row[item]);
                }
              }
            });
            return shouldReturnRow;
          });
        }
        if (!filteredRows) {
          filteredRows = this.getRowData();
        }
        return _.chain(filteredRows)
          .map((row) => row[this.getFilterProperty(property)])
          .flatten()
          .sortBy((x) => x)
          .uniq()
          .compact()
          .value();
      }
      return _.chain(this.getRowData())
        .map((row) => row[this.getFilterProperty(property)])
        .flatten()
        .sortBy((x) => x)
        .uniq()
        .compact()
        .value();
    };

    const dynamicProperties = (prop, refresh = true, addAll = true) => {
      if (addAll) return [ALL, ...byFilterProp(prop, refresh)];
      else return [...byFilterProp(prop, refresh)];
    };

    const options = {
      ...Constants.FILTER_VALUES,
      assetClass: dynamicProperties('assetClass'),
      clientAssetClass: dynamicProperties('clientAssetClass'),
      betaGroup: dynamicProperties('betaGroup', false),
      reasonForMeeting: dynamicProperties('reasonForMeeting'),
      deliverableType: dynamicProperties('deliverableType'),
      type: dynamicProperties('type'),
      category: dynamicProperties('category'),
      newStatus: dynamicProperties('newStatus'),
      fplListName: dynamicProperties('fplListName'),
      investmentFirmFilter: dynamicProperties('investmentFirmFilter'),
      managerDocumentType: dynamicProperties('managerDocumentType'),
    };

    const isFilterPropertyLengthLessThanThree = filterProperty.length < 3;

    const renderFilterComponent = (filterProperty) => {
      const gridOptions = this.getGridOptions();
      const filterPropertyBaseName = this.getBaseName(filterProperty);
      const filterLabel = Constants.FILTER_LABELS[filterProperty];

      return (
        <Col
          className="filters-component"
          key={`${filterProperty}-component`}
          id={`${filterProperty}-component`}>
          {Boolean(filterLabel.label) && (
            <Col
              className={`${
                filterLabel.class ? `${filterLabel.class}` : 'filters-label'
              }
              `}
              key={`${filterProperty}-label`}
              id={`${filterProperty}-label`}>
              <span>
                {Constants.FILTER_LABELS[filterProperty].label}
                {Boolean(filterLabel.showCounter) && (
                  <> ({options[filterProperty].length - 1})</>
                )}
              </span>
            </Col>
          )}
          <Col
            className="filters-selects"
            key={`${filterProperty}-selects`}
            id={`${filterProperty}-selects`}>
            <DocumentsFilter
              selected={
                this.state.filter[filterPropertyBaseName]?.length > 0
                  ? this.state.filter[filterPropertyBaseName]
                  : null || ALL
              }
              options={options[filterProperty]}
              filterProperty={filterProperty}
              isMultiSelect={
                Constants.FILTER_LABELS[filterProperty].multiSelect
              }
              hasLoaded={
                this.props[gridOptions.data].data &&
                !this.props[gridOptions.data].loading
              }
              isLoading={this.props[gridOptions.data].loading}
              inputType={Constants.FILTER_LABELS[filterProperty].inputType}
              onSelect={(filter) => this.handleFilterSelect(filter)}
              documentView={this.props.documentView}
            />
          </Col>
        </Col>
      );
    };

    const firstRow = (
      <Row className="dynamic-fields" lg="auto" noGutters>
        {filterProperty
          .filter((filterProp) => Constants.FILTER_LABELS[filterProp].row === 1)
          .map((filterProp) => renderFilterComponent(filterProp))}
      </Row>
    );

    const secondRow = (
      <Row className="static-fields" lg="auto" noGutters>
        {filterProperty
          .filter((filterProp) => Constants.FILTER_LABELS[filterProp].row === 2)
          .map((filterProp) => renderFilterComponent(filterProp))}
      </Row>
    );

    if (isFilterPropertyLengthLessThanThree) {
      return filterProperty.map((filterProperty) =>
        renderFilterComponent(filterProperty)
      );
    }
    return (
      <Container fluid className="p-0">
        {firstRow}
        {secondRow}
      </Container>
    );
  };

  renderWhatIsBetaGroup() {
    if (this.props.documentView !== Constants.PLAN_FOCUSED_PLACEMENT_LISTS) {
      return null;
    }
    return (
      <Row className="mb-2">
        <Col md={6}>
          <Button
            color="link"
            className="whatIsLink"
            onClick={this.props.showWhatAreBetaGroups}>
            What are Asset Groups?
          </Button>
        </Col>
      </Row>
    );
  }

  getGridOptions = () => {
    return gridOptions[this.props.documentView] || {};
  };

  fetchData = _.debounce(() => {
    const { viewParams, dispatch } = this.props;
    const { loader } = this.getGridOptions();
    if (loader) {
      const loadAction = loader(viewParams);
      if (loadAction) {
        dispatch(loadAction);
      }
    }
  }, 400);

  getRowData = () => {
    const { viewParams } = this.props;
    const { data, mapper } = this.getGridOptions();
    if (data) {
      const resource = this.props[data];
      if (resource) {
        this.fetchData();
        if (data === 'all_managers' && !resource.data) {
          return undefined;
        }
        if (data === 'nepc_published' && resource.data) {
          return mapper(
            resource.data.filter(
              (doc) =>
                !(doc.publishingLocation === Constants.DOCUMENT_MARKET_UPDATES)
            )
          );
        }
        return mapper(resource.data, viewParams) || [];
      }
    }
    return [];
  };

  openMeetingNotesModal = () => {
    const data = this.getRowData();
    const hasData = data?.length > 0 ? true : false;
    if (hasData) {
      if (this.state.openModal && this.props.meetingNoteWriteUpId) {
        data.forEach((meetingNote) => {
          const slugedWriteUpId = slug(meetingNote.writeUpId);
          if (slugedWriteUpId === this.props.meetingNoteWriteUpId) {
            this.setState({
              openModal: false,
            });
            return this.props.openMeetingNote(meetingNote);
          }
        });
      }
    }
    return null;
  };

  openDDEventsModal = () => {
    const data = this.getRowData();
    const hasData = data?.length > 0 ? true : false;
    if (hasData) {
      if (this.state.openModal && this.props.ddEventId) {
        data.forEach((ddEvent) => {
          const slugedEventId = slug(ddEvent.eventId);
          if (slugedEventId === this.props.ddEventId) {
            this.setState({
              openModal: false,
            });
            return this.props.openDDEvent(ddEvent);
          }
        });
      }
    }
    return null;
  };

  getNoRowsMessage = () => {
    const { data, onErrorMessage } = this.getGridOptions();
    if (data && onErrorMessage) {
      const resource = this.props[data];
      if (resource && resource.error) {
        return onErrorMessage;
      }
    }
    // if (
    //   data === 'all_managers' &&
    //   (!this.state.filter?.betaGroup ||
    //     this.state.filter?.betaGroup[0] === 'All')
    // ) {
    //   return 'Select a beta group';
    // }
    return 'No Rows To Show';
  };

  onCustomFilterRow = (data) => {
    const { filterDocumentOnGrid } = this.props;
    const dataKey = this.getGridOptions().dataKey;
    if (!filterDocumentOnGrid.key || filterDocumentOnGrid.key === dataKey) {
      this.getFilteredIDs(data.api, dataKey);
    } else {
      this.props.changeGridFilter(null, null, false);
    }
  };

  getFilteredIDs = (api, key) => {
    const filteredIDs = [];
    api.forEachNodeAfterFilter((node, index) => {
      if (node.data) {
        filteredIDs.push(node.data[key]);
      }
    });
    const hasFilter = filteredIDs.length < this.getRowData().length;
    this.props.changeGridFilter(key, filteredIDs, hasFilter);
  };

  renderManagerDisclaimer() {
    const isManagerList = this.props.documentView === Constants.PLAN_PORTFOLIO;
    if (!isManagerList) {
      return null;
    }
    return (
      <Col className="managerDisclaimer">
        <span>
          The as of date for private investment vehicles reflects the last
          valuation date plus or minus inter-period cash flows. In general,
          these valuations are lagged by one to two quarters
        </span>
      </Col>
    );
  }

  render() {
    const { visible, viewParams, documentView } = this.props;
    const options = this.getGridOptions();
    if (!visible || !options || !options.loader) {
      return null;
    }
    const columnDefs = _.compact(
      _.map(options.columnDefs, (colDef) => {
        const { hide } = colDef;
        if (hide && typeof hide === 'function') {
          if (hide(viewParams)) {
            return null;
          }
        }
        return { ...colDef, hide: false };
      })
    );
    const hasLoaded = () => {
      return Boolean(
        (this.props[options.data]?.data ||
          this.props[options.data]?.data === '') &&
          !this.props[options.data]?.loading
      );
    };

    const isLoading = () => {
      return Boolean(this.props[options.data]?.loading);
    };

    const changeDiverseVisibility = (e) => {
      this.setState({ hideDiverseColumns: e.target.checked });
      if (e.target.checked) {
        return this.treeGridRef.current?.gridApi.setGridOption('columnDefs', [
          ...myManagersAssetColumnDefs,
          ddratingColumn,
          ...baseEndColumns,
        ]);
      } else if (this.props.documentView === Constants.PLAN_PORTFOLIO) {
        return this.treeGridRef.current?.gridApi.setGridOption('columnDefs', [
          ...myManagersAssetColumnDefs,
          ...baseRatingColumnDefs,
          ...baseEndColumns,
        ]);
      }
    };

    return (
      <div>
        <Fragment>
          <Row noGutters>
            <Col md={12} lg={12}>
              {this.renderDocumentType()}
              <Row noGutters>
                <Col md={9} lg={10}>
                  <div className="filters">{this.renderFiltering()}</div>
                </Col>
                {this.props.documentView === Constants.PLAN_PORTFOLIO && (
                  <Col className="diverse-switch" md={3} lg={2}>
                    <FormGroup switch disabled>
                      <Input
                        type="switch"
                        name="diverseSwitch"
                        id="diverse-switch"
                        checked={this.state.hideDiverseColumns}
                        onChange={(e) => changeDiverseVisibility(e)}
                      />
                      <Label check>Hide ESG/DEI</Label>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {Boolean(options.treeTable) && (
            <div className="reset-buttons-line">{this.renderResetButton()}</div>
          )}
        </Fragment>
        <Fragment>
          <Row>
            <Col md={12}>
              {Boolean(options.treeTable) && (
                <DocumentsGridTree
                  columnDefs={options.columnDefs}
                  viewParams={viewParams}
                  className={documentView}
                  saveSortState={this.props.saveSortState}
                  filterProperty={this.getFilterProperty()}
                  filterValue={this.getFilteringValue()}
                  rowData={this.getRowData()}
                  noRowsMessage={this.getNoRowsMessage()}
                  onCustomFilterRow={this.onCustomFilterRow}
                  hasLoaded={hasLoaded()}
                  isLoading={isLoading()}
                  headerHeight={this.state.hideDiverseColumns ? 65 : 32.5}
                  gridContext={options.data}
                  rowHeight={options.rowHeight}
                  groupDefaultExpanded={options.groupDefaultExpanded}
                  getDataPath={options.getDataPath}
                  autoGroupColumnDef={options.autoGroupColumnDef}
                  ref={this.treeGridRef}
                />
              )}
              {Boolean(!options.treeTable) && (
                <DocumentsGrid
                  columnDefs={columnDefs}
                  viewParams={viewParams}
                  className={documentView}
                  saveSortState={this.props.saveSortState}
                  filterProperty={this.getFilterProperty()}
                  filterValue={this.getFilteringValue()}
                  rowData={this.getRowData()}
                  noRowsMessage={this.getNoRowsMessage()}
                  onCustomFilterRow={this.onCustomFilterRow}
                  hasLoaded={hasLoaded()}
                  isLoading={isLoading()}
                  gridContext={options.data}
                  rowHeight={options.rowHeight}
                  groupDefaultExpanded={options.groupDefaultExpanded}
                  getDataPath={options.getDataPath}
                  autoGroupColumnDef={options.autoGroupColumnDef}
                />
              )}
              {this.renderManagerDisclaimer()}
            </Col>
          </Row>
        </Fragment>
        {this.openDDEventsModal()}
        {this.openMeetingNotesModal()}
      </div>
    );
  }
}

const resourcesPropTypes = _.reduce(
  gridOptions,
  (opts, option) => ({
    ...opts,
    [option.data]: option.isRequired
      ? PropTypes.object.isRequired
      : PropTypes.object,
  }),
  {}
);

DocumentsList.propTypes = {
  viewParams,
  documentView: PropTypes.string,
  visible: PropTypes.bool.isRequired,

  dispatch: PropTypes.func,
  printPortfolio: PropTypes.func.isRequired,
  redirectPlan: PropTypes.func,
  saveSortState: PropTypes.func,
  changeDocumentsViewFilter: PropTypes.func,
  showWhatAreBetaGroups: PropTypes.func,
  meetingNoteWriteUpId: PropTypes.string,
  ddEventId: PropTypes.string,
  openModal: PropTypes.bool,

  filterByDocumentType: PropTypes.object,
  filterDocumentOnGrid: PropTypes.object,
  managerSearchList: PropTypes.array,
  openMeetingNote: PropTypes.func,
  openDDEvent: PropTypes.func,

  myManagers: PropTypes.array,
  myMeetingNotes: PropTypes.array,
  planManagerActivities: PropTypes.array,
  user: PropTypes.object,

  ...resourcesPropTypes,
};

const mapStateToProps = (state) => {
  const resources = _.reduce(
    gridOptions,
    (opts, option) => ({
      ...opts,
      [option.data]: state.resources[option.data],
    }),
    {}
  );
  return {
    ...resources,
    managerSearchList: state.resources.search_managers.data || [],
    filterByDocumentType: state.ui.filterByDocumentType,
    filterDocumentOnGrid: state.ui.filterDocumentOnGrid,
    myManagers: state.resources.my_managers.data,
    myMeetingNotes: state.resources.research_writeups.data,
    planManagerActivities: state.resources.plan_manager_activities.data,
    user: state.okta.user,
    dispatchPrinting: PropTypes.func,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    dispatchPrinting: (...params) => dispatch(dispatchPrinting(...params)),
    printPortfolio: (...params) => dispatch(printPortfolio(...params)),
    showWhatAreBetaGroups: () => dispatch(showWhatAreBetaGroups()),
    redirectPlan: (...a) => dispatch(redirectPlan(...a)),
    saveSortState: (...params) => dispatch(saveSortState(...params)),
    changeDocumentsViewFilter: (...a) =>
      dispatch(changeDocumentsViewFilter(...a)),
    changeGridFilter: (...a) => dispatch(changeGridFilter(...a)),
    openMeetingNote: (...params) => dispatch(openMeetingNote(...params)),
    openDDEvent: (...params) => dispatch(openDDEvent(...params)),
  };
};

DocumentsList.displayName = 'DocumentsList';

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);
