import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, TabContent, TabPane, Button } from 'reactstrap';
import _ from 'lodash';

import { renderTableGrid } from '../documents/TablePrintHtml';
import { managerManagerActivitiesColumnDefs } from '../documents/ColumnDefs';
import ContentFields from './ContentFields';
import SimpleTabsMenu from '../../tabsmenu/SimpleTabsMenu';
import { Constants, Events } from '../../../services/Constants';
import {
  printEVestmentNarratives,
  loadManagerEVest,
  printPortfolio,
} from '../../../actions';

const managerEVestStrategyLayout = [
  { title: 'Firm background', field: 'firmBackground' },
  { title: 'Ventures', field: 'ventures' },
  { title: 'Ownership', field: 'ownership' },
  { title: 'Ligitation', field: 'ligitation' },
  { title: 'Comments', field: 'comments' },
];

export class ManagerContents extends Component {
  renderTabs() {
    const options = [
      { title: Constants.PLAN_MANAGER_STRATEGIES, path: '/strategies' },
      { title: Constants.EMPLOYEE_MANAGER_ACTIVITIES, path: '/activities' },
      { title: Constants.PLAN_MANAGER_EVEST_NARRATIVES, path: '/evest' },
    ];
    const { managerUrl, documentView } = this.props;
    return (
      <SimpleTabsMenu
        options={options}
        baseUrl={managerUrl}
        selected={documentView}
        prefix="FPL "
        prefix2="Employee "
      />
    );
  }

  renderContents() {
    return (
      <TabContent activeTab={this.props.documentView}>
        <TabPane tabId={Constants.PLAN_MANAGER_STRATEGIES}>
          {this.renderStrategies()}
        </TabPane>
        <TabPane tabId={Constants.EMPLOYEE_MANAGER_ACTIVITIES}>
          {this.renderManagerActivities()}
        </TabPane>
        <TabPane tabId={Constants.PLAN_MANAGER_EVEST_NARRATIVES}>
          {this.renderEVest()}
        </TabPane>
      </TabContent>
    );
  }

  renderStrategies() {
    return null;
  }

  renderManagerActivities() {
    const { viewParams, managerActivities } = this.props;
    const { documentView } = viewParams;

    const print = () => {
      let grid = '';
      grid = renderTableGrid(
        managerManagerActivitiesColumnDefs,
        viewParams,
        managerActivities,
        Constants.ROWS_PER_PAGE_DD_EVENTS,
        { colId: 'date', sort: 'desc' },
        documentView
      );
      const event = Events.PRINT_PORTFOLIO_DDS;

      const planData = {
        planName: viewParams.managerSummary.firmName,
        // user: props.user.email,
        documentType: 'Portfolio Summary',
      };

      const html = `
      <h2>Portfolio Summary</h2>
      <div class="grid-view">
        ${grid}
      </div>
    `;
      this.props.printPortfolio(
        event,
        planData,
        viewParams.managerSummary.firmName,
        html
      );
    };

    return (
      <>
        <Row className="fpl-grid-top justify-content-end">
          <Col lg={12} className="text-right downloadCard">
            <Button
              id="downloadPdfButton"
              color="secondary"
              className="text-nowrap mx-auto downloadButtom"
              onClick={print}>
              Download PDF
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  printEVestmentNarratives = () => {
    const getHtml = selector => document.querySelector(selector).innerHTML;
    const header = getHtml('.fpl-header');
    const content = getHtml('.fpl-nepc-eVestment');
    // get strategy name as the eVest narrative, does not have a property as name
    const firmName = _.get(this.props.managerSummary, 'firmName', '') || '';
    const sendData = {
      userMail: this.props.user.email,
      documentType: 'eVestment Narratives',
    };
    this.props.printEVestmentNarratives(sendData, firmName, header, content);
  };

  renderPrintEVestmentNarratives() {
    if (_.isEmpty(this.props.evestStrategy)) {
      return null;
    }
    return (
      <Button
        color="secondary"
        className="text-nowrap narrative-download-btn"
        onClick={this.printEVestmentNarratives}>
        Download PDF
      </Button>
    );
  }

  renderEVest = () => {
    const { managerId, documentView } = this.props.viewParams;
    if (
      !managerId ||
      documentView !== Constants.PLAN_MANAGER_EVEST_NARRATIVES
    ) {
      return null;
    }
    this.props.loadManagerEVest(managerId);
    const whenEmpty = (
      <span className="font-italic">
        EVestment does not provide any information for this manager.
      </span>
    );
    return (
      <Fragment>
        <Row>
          <Col md={12} className="d-flex strategyDownloadDetail">
            <span className="detailTitle" />
            {this.renderPrintEVestmentNarratives()}
          </Col>
        </Row>
        <Row className="fpl-nepc-eVestment">
          <Col>
            <ContentFields
              layout={managerEVestStrategyLayout}
              data={this.props.evestStrategy}
              whenEmpty={whenEmpty}
            />
            <br />
            <span className="fpl-evest-source">Source: eVestment.</span>
          </Col>
        </Row>
      </Fragment>
    );
  };

  isManagerTabs() {
    return (
      this.props.documentView === Constants.PLAN_MANAGER_STRATEGIES ||
      this.props.documentView === Constants.EMPLOYEE_MANAGER_ACTIVITIES ||
      this.props.documentView === Constants.PLAN_MANAGER_EVEST_NARRATIVES
    );
  }

  render() {
    if (this.props.managerSummary && this.isManagerTabs()) {
      return (
        <Row>
          <Col className="mt-3" md={12}>
            {this.renderTabs()}
          </Col>
          <Col md={12}>{this.renderContents()}</Col>
        </Row>
      );
    }
    return null;
  }
}

ManagerContents.propTypes = {
  documentView: PropTypes.string,
  managerSummary: PropTypes.object,
  managerUrl: PropTypes.string,
  printEVestmentNarratives: PropTypes.func,
  loadManagerEVest: PropTypes.func,
  printPortfolio: PropTypes.func,
  user: PropTypes.object,
  nepcStrategy: PropTypes.object,
  evestStrategy: PropTypes.object,
  viewParams: PropTypes.object,

  managerActivities: PropTypes.array,
};

const mapStateToProps = state => {
  const {
    okta: { user },
  } = state;
  return {
    managerActivities: state.resources.manager_activities.data || [],
    evestStrategy: state.resources.manager_evest_narratives.data || {},
    user,
  };
};

export default connect(mapStateToProps, {
  printEVestmentNarratives,
  loadManagerEVest,
  printPortfolio,
})(ManagerContents);
