import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { dateColumn } from '../../services/DateService';
import DocumentsGrid from '../plans/documents/DocumentsGrid';
import { textColumn } from '../../services/StringService';

const StatusRenderer = ({ value, data, context }) => {
  const onClick = () => context.editSystemMessage(data);
  const icon = value ? 'toggle-on' : 'toggle-off';
  const title = value ? 'On' : 'Off';
  return (
    <span className="fa-lg" onClick={onClick}>
      <i className={`fa fa-${icon}`} title={title}>
        &nbsp;
      </i>{' '}
      <i className="fa fa-edit" title="Edit">
        &nbsp;
      </i>
    </span>
  );
};
StatusRenderer.propTypes = {
  value: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export class MessagesListAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  static columnDefs = [
    {
      field: 'subject',
      flex:1,
      headerName: 'Subject',
      ...textColumn,
    },
    {
      field: 'startDate',
      headerName: 'Start date',
      ...dateColumn,
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration date',
      sort: 'desc',
      ...dateColumn,
    },
    {
      field: 'active',
      headerName: 'Status',
      cellRenderer: StatusRenderer,
      width: 120,
      suppressSizeToFit: true,
    },
  ];

  load = _.debounce(
    () => this.props.loadAll().then(() => this.setState({ loaded: true })),
    1000
  );

  render() {
    if (!this.props.allLoaded) {
      this.load();
    }
    const context = {
      editSystemMessage: this.props.editSystemMessage,
    };
    return (
      <div className="messagesGrid mt-3 mb-3 ag-theme-balham">
        <DocumentsGrid
          context={context}
          columnDefs={MessagesListAdmin.columnDefs}
          rowData={this.props.all}
          viewParams={this.props.viewParams}
          noRowsMessage="No rows"
          hasLoaded={this.state.loaded || false}
        />
      </div>
    );
  }
}

MessagesListAdmin.propTypes = {
  loadAll: PropTypes.func.isRequired,
  all: PropTypes.array.isRequired,
  allLoaded: PropTypes.bool.isRequired,

  editSystemMessage: PropTypes.func.isRequired,
  viewParams: PropTypes.object,
};

export default MessagesListAdmin;
