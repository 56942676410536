import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, Alert } from 'reactstrap';

export const RunNotificationEmployeesModal = _props => {
  return (
    <Modal
      isOpen={_props.state.showDangerModalEmployees}
      toggle={_props.toggleDangerModalEmployees}>
      <ModalBody>
        <Alert color="danger">
          <h4 className="alert-heading">Warning</h4>
          Warning! This process will send the notification for all the enabled
          employee audience. Are you sure do you want to start this process out
          of scheduled time?
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            _props.props
              .sendNotificationEmployees()
              .then(_props.toggleDangerModalEmployees)
          }>
          Run Notifications
        </Button>
        <Button color="secondary" onClick={_props.toggleDangerModalEmployees}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RunNotificationEmployeesModal.propTypes = {
  state: PropTypes.object,
  toggleDangerModalEmployees: PropTypes.func,
  sendNotificationEmployees: PropTypes.func,
};
