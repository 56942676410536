import { addEvent } from './EventActions';
import { Events } from '../services/Constants';
import PrintService from '../services/PrintService';
import { withLoading } from './CommonActions';
import { slugUnderscore } from '../services/SlugService';

const printDocument = (
  data,
  filename,
  template,
  html,
  orientation = 'portrait'
) => {
  return PrintService().printHtmlPdf(
    data,
    `${slugUnderscore(filename)}.pdf`,
    template,
    orientation,
    html
  );
};

export const printMeetingNotes = withLoading((sendData, title, html) => {
  const filteredHtml = html
    .replaceAll('<p>\n  <strong>', '<br><br><p>\n <strong>')
    .replace('<br><br>', '<br>');
  return dispatch => {
    dispatch(addEvent(Events.PRINT_MEETING_NOTES, title));
    return printDocument(
      sendData,
      `${title}-Meeting-Notes`,
      'meeting_notes',
      filteredHtml
    );
  };
});

export const printDDEvent = withLoading((sendData, title, html) => {
  const filteredHtml = html
    .replaceAll('<p>\n  <strong>', '<br><br><p>\n <strong>')
    .replace('<br><br>', '<br>');
  return dispatch => {
    dispatch(addEvent(Events.PRINT_DUE_DILLIGENCE_EVENTS, title));
    return printDocument(
      sendData,
      `${title}-DD-Events`,
      'DD_Event',
      filteredHtml
    );
  };
});

export const printMessages = withLoading((sendData, html) => {
  return dispatch => {
    dispatch(addEvent(Events.PRINT_MESSAGES, sendData.subject));
    return printDocument(
      sendData,
      `${sendData.subject}-Announcement`,
      'announcements',
      html
    );
  };
});

export const printPortfolio = withLoading((event, data, title, html) => {
  return dispatch => {
    dispatch(addEvent(event, title));
    return printDocument(
      data,
      `${title}-Portfolio`,
      'portfolio',
      html
        .replaceAll('Owned/Led', 'Owned/ Led')
        .replaceAll('Daily/Weekly', 'Daily/ Weekly')
        .replaceAll('Unidentified', '')
    );
  };
});

export const printNepcOpnion = withLoading(
  (sendData, headerHtml, view, docView, contentHtml) => {
    return dispatch => {
      dispatch(addEvent(Events.PRINT_NEPC_OPINION, sendData.strategyName));
      const html = `
    <div class="nepc-opinion-report">
      ${headerHtml}
      ${contentHtml}
    </div>
    `;
      return printDocument(
        sendData,
        `${sendData.strategyName}-${docView}`,
        view,
        html
      );
    };
  }
);

export const printEVestmentNarratives = withLoading(
  (sendData, headerHtml, contentHtml) => {
    return dispatch => {
      dispatch(
        addEvent(Events.PRINT_EVESTMENT_NARRATIVE, sendData.strategyName)
      );
      const html = `
    <div class="eVestment-narrative-report">
      ${headerHtml}
      ${contentHtml}
    </div>
    `;
      return printDocument(
        sendData,
        `${sendData.strategyName}-Evestment Narratives`,
        'evest_narratives',
        html
      );
    };
  }
);
